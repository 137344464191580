import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function SiteCapabilities() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    const [siteCapabilities, setSiteCapabilities] = useState([]);

    useEffect(() => {
        setSiteCapabilities([...siteData.capabilities])
    }, [siteData])

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Capabilities</h5>

                    <Link to="/site/capabilities/edit-capabilities"><button className="btn-d">Edit</button></Link>
                </div>

                <div className="bg-white p-3">
                    <div className="row">
                        {
                            siteCapabilities.length === 0 ?
                                <>
                                    <h6>You haven't added any site capabilities yet!!!</h6>
                                </>
                                : <>
                                    {
                                        siteCapabilities.map((capability) =>
                                            <div className="col-lg-4 si-sec-b" key={capability._id}>
                                                <li className="py-2"> {capability.name} </li>
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}