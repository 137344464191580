import React, { useContext, useState } from 'react';
import axios from 'axios';
import { GlobalState } from '../../../GlobalState';
import { Link } from 'react-router-dom';

function DocUpload() {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [selectedFiles, setSelectedFiles] = useState(null);

    const state = useContext(GlobalState);
    const [siteData] = state.siteData;
    const [, setAlert] = state.alert;
    const [formReset, setFormReset] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFiles) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'Please select atleast one file',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('files', selectedFiles[i]);
            }

            const uploadResponse = await axios.post(`${API_ENDPOINT}/site-docs`, formData);

            const secondEndpointResponse = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/upload-documents`, {
                files: [...uploadResponse.data.files],
                siteId: siteData._id
            });

            // console.log('Second Endpoint Response:', secondEndpointResponse.data);
            setAlert({
                failure: false,
                success: true,
                failureMsg: secondEndpointResponse.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setFormReset(true);
            setTimeout(() => {
                setFormReset(false);
                setSelectedFiles(null);
            }, 2000);
        } catch (error) {
            console.error('Error:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    };

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-4">
                    <h5>Please upload relevant site documents</h5>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label className='form-label' htmlFor='upload'>
                            <i>*you can select multiple files at the same time</i>
                        </label>
                        <input type='file' className='form-control form-control-md' multiple onChange={handleFileChange} value={formReset ? '' : undefined} />
                    </div>

                    {/* Display the list of selected files */}
                    {selectedFiles && (
                        <div className='mb-4'>
                            <h6>Selected Files:</h6>
                            <ul>
                                {Array.from(selectedFiles).map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className='d-flex'>
                        <Link to="/site/documents"><button type='button' className='btn-d me-2'>Cancel</button></Link>
                        <button type='submit' className='btn-a'>Save</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default DocUpload;
