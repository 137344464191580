import React, { useEffect, useContext, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { GlobalState } from '../../GlobalState';
import Logo from '../../assets/images/logo.jpg';

export default function ActivateEmail() {
    const { activation_token } = useParams()
    const state = useContext(GlobalState);
    const [, setAlert] = state.alert;

    const [activationSuccess, setActivationSuccess] = useState(false)
    const [activated, setActivated] = useState(false);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const activationEmail = useCallback(async (activation_token) => {
        try {
            const res = await axios.post(`${API_ENDPOINT}/verify-acc`, { activation_token })
            setActivationSuccess(true)
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setActivated(true);
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            setActivated(true);
        }
    }, [API_ENDPOINT, setAlert, alert])

    useEffect(() => {
        if (activation_token && !activated) {
            activationEmail(activation_token)
        }
    }, [activation_token, activationEmail, activated])

    return (
        <>
            <div className="auth-page">
                <div className="auth-form">
                    <div className="bg-white py-4 px-3">
                        <div className="text-center py-2">
                            <img src={Logo} alt="logo" className="auth-logo" />
                            <h6 className="auth-cap-txt pt-2" style={{ fontSize: '18px' }}>Activate your account</h6>
                        </div>
                        <div className='my-5'>
                            {
                                activationSuccess && <>
                                    <div className='card p-5'>
                                        <h6>Your account has been activated successfully</h6>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="bg-light pt-3 pb-2">
                        <p className="text-center auth-oth-link">Proceed to login? <Link to="/login">Login</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}
