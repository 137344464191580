import React, { useContext, useState } from 'react';
import { GlobalState } from '../../GlobalState';
import axios from 'axios';
import Header from '../../components/Header/Header';
import CSidebar from '../../components/Nav/CSidebar';

const initialState = {
    site_name: "",
    type_of_site: "",
    site_phone_number: "",
    is_phase_one_site: false,
    is_phase_two_site: false,
    is_phase_three_site: false,
    is_phase_four_site: false,
    other_site_type: "",
    street_address: "",
    apt_suite_ect: "",
    city: "",
    state_province: "",
    country: "",
    zip_postcode: "",
    work_with_other_orgs: "",
    written_sops: "",
    dedicated_patient_recruitment_personnel: "",
    dedicated_data_entry_staff: "",
    dedicated_regulatory_personnel: "",
    year_established: "",
    no_of_coordinators: ""
}

export default function NewSite() {
    const [newSite, setNewSite] = useState(initialState)
    const {
        site_name, type_of_site, site_phone_number, is_phase_one_site, is_phase_two_site, is_phase_three_site, is_phase_four_site, other_site_type,
        street_address, apt_suite_ect, city, state_province, country, zip_postcode, work_with_other_orgs, written_sops,
        dedicated_patient_recruitment_personnel, dedicated_data_entry_staff, dedicated_regulatory_personnel, year_established, no_of_coordinators
    } = newSite

    const state = useContext(GlobalState);
    const [userData] = state.userData;
    const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        // Handle checkbox separately
        if (type === 'checkbox') {
            setNewSite({ ...newSite, [name]: checked });
        } else {
            setNewSite({ ...newSite, [name]: value });
        }
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const createSite = async (e) => {
        e.preventDefault(newSite)

        console.log()

        if (!site_name || !type_of_site || !site_phone_number || !is_phase_one_site || !street_address || !apt_suite_ect || !city || !state_province || !country
            || !zip_postcode || !work_with_other_orgs || !written_sops || !dedicated_patient_recruitment_personnel || !dedicated_data_entry_staff || !dedicated_regulatory_personnel
            || !year_established || !no_of_coordinators) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(
                `${API_ENDPOINT}/sites`,
                {
                    ...newSite, user_id: userData._id,
                    is_phase_one_site: Boolean(newSite.is_phase_one_site),
                    is_phase_two_site: Boolean(newSite.is_phase_two_site),
                    is_phase_three_site: Boolean(newSite.is_phase_three_site),
                    is_phase_four_site: Boolean(newSite.is_phase_four_site),
                },
                { withCredentials: true, credentials: 'include' }
            )
            // console.log(res)
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setNewSite(initialState);
            window.location.href = "/";
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <>
            <div className="d-flex profile" >
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                <h5 style={{ fontSize: '18px', fontWeight: '600' }}>Create a New Site</h5>
                                <p style={{ fontSize: '14px', fontWeight: '400' }}>Please enter all necessary information to create a new site</p>
                            </div>

                            <div className="">
                                <div className="row py-2">
                                    <div className='col-lg-12 bg-white p-3'>
                                        <form onSubmit={createSite}>
                                            <div className='section-one'>
                                                <div className="row mb-3">
                                                    <div className='col'>
                                                        <label htmlFor="site_name" className="form-label text-uppercase form-site-in">Site Name</label>
                                                        <input type="text" id="fullname" name="site_name" className="form-control form-control-sm"
                                                            value={site_name} onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="phase_of_site" className="form-check-label text-uppercase form-site-in">Study Phase(s) Interest</label>

                                                        <div className='d-flex'>
                                                            <div className='me-2'>
                                                                <input className="form-check-input me-2" type="checkbox" id="is_phase_one_site" name='is_phase_one_site'
                                                                    value={is_phase_one_site} onChange={handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="is_phase_one_site">
                                                                    Phase 1
                                                                </label>
                                                            </div>

                                                            <div className='me-2'>
                                                                <input className="form-check-input me-2" type="checkbox" id="is_phase_two_site" name="is_phase_two_site"
                                                                    value={is_phase_two_site} onChange={handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                                                                    Phase 2
                                                                </label>
                                                            </div>

                                                            <div className='me-2'>
                                                                <input className="form-check-input me-2" type="checkbox" id="is_phase_three_site" name='is_phase_three_site'
                                                                    value={is_phase_three_site} onChange={handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                                                                    Phase 3
                                                                </label>
                                                            </div>

                                                            <div className=''>
                                                                <input className="form-check-input me-2" type="checkbox" id="is_phase_four_site" name='is_phase_four_site'
                                                                    value={is_phase_four_site} onChange={handleChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                                                                    Phase 4
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className='col'>
                                                        <label htmlFor="type_of_site" className="form-label text-uppercase form-site-in">Type of Site</label>
                                                        <select id="type_of_site" name="type_of_site" className='form-select'
                                                            value={type_of_site} onChange={handleChange}
                                                        >
                                                            <option>...</option>
                                                            <option value="dedicated research center">Dedicated Research Center</option>
                                                            <option value="solo private private with research">Solo Private Practice with Research</option>
                                                            <option value="group private practice with research">Group Private Practice with Research</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>

                                                    <div className='col'>
                                                        <label htmlFor="other_site_type" className="form-label text-uppercase form-site-in">If Other</label>
                                                        <input type="text" id="other_site_type" name="other_site_type" className="form-control form-control-sm"
                                                            value={other_site_type} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <label htmlFor="site_phone_number" className="form-label text-uppercase form-site-in">Site Phone Number</label>
                                                        <input type="text" id="site_phone_number" name="site_phone_number" className="form-control form-control-sm"
                                                            value={site_phone_number} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='section-two py-3'>
                                                <h6>Site Address</h6>

                                                <div className="row mb-3">
                                                    <div className='col'>
                                                        <label htmlFor="street_address" className="form-label text-uppercase form-site-in">Street Address</label>
                                                        <input type="text" id="street_address" name="street_address" className="form-control form-control-sm"
                                                            value={street_address} onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="apt_suite_ect" className="form-label text-uppercase form-site-in">Apt, Suite, etc</label>
                                                        <input type="text" id="apt_suite_ect" name="apt_suite_ect" className="form-control form-control-sm"
                                                            value={apt_suite_ect} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className='col'>
                                                        <label htmlFor="city" className="form-label text-uppercase form-site-in">City</label>
                                                        <input type="text" id="city" name="city" className="form-control form-control-sm"
                                                            value={city} onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="state_province" className="form-label text-uppercase form-site-in">State/Province</label>
                                                        <input type="text" id="state_province" name="state_province" className="form-control form-control-sm"
                                                            value={state_province} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className='col'>
                                                        <label htmlFor="country" className="form-label text-uppercase form-site-in">Country</label>
                                                        <input type="text" id="country" name="country" className="form-control form-control-sm"
                                                            value={country} onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="zip_postcode" className="form-label text-uppercase form-site-in">Zip/Postcode</label>
                                                        <input type="text" id="zip_postcode" name="zip_postcode" className="form-control form-control-sm"
                                                            value={zip_postcode} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='section-three py-3'>
                                                <h6>Additional Information</h6>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <label htmlFor="fullname" className="form-label text-uppercase form-site-in">Do you work with other TMOs, SMOs or Contract Orgs?</label>
                                                        <select className='form-select' name='work_with_other_orgs' value={work_with_other_orgs} onChange={handleChange}>
                                                            <option>...</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="year_established" className="form-label text-uppercase form-site-in">What year was your site established?</label>
                                                        <input type="text" id="year_established" name="year_established" className="form-control form-control-sm"
                                                            value={year_established} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <label htmlFor="written_sops" className="form-label text-uppercase form-site-in">Do you have written SOPs?</label>
                                                        <select className='form-select' name='written_sops' value={written_sops} onChange={handleChange}>
                                                            <option>...</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="no_of_coordinators" className="form-label text-uppercase form-site-in">How many coordinator(s) does your site have?</label>
                                                        <input type="text" id="no_of_coordinators" name="no_of_coordinators" className="form-control form-control-sm"
                                                            value={no_of_coordinators} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <label htmlFor="dedicated_patient_recruitment_personnel" className="form-label text-uppercase form-site-in">
                                                            Does your site have dedicated patient recruitment personnel?
                                                        </label>
                                                        <select className='form-select' id='dedicated_patient_recruitment_personnel' name='dedicated_patient_recruitment_personnel'
                                                            value={dedicated_patient_recruitment_personnel} onChange={handleChange}
                                                        >
                                                            <option>...</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                    <div className='col'>
                                                        <label htmlFor="dedicated_regulatory_personnel" className="form-label text-uppercase form-site-in">
                                                            Does your site have dedicated regulatory personnel?
                                                        </label>
                                                        <select className='form-select' id='dedicated_regulatory_personnel' name='dedicated_regulatory_personnel'
                                                            value={dedicated_regulatory_personnel} onChange={handleChange}
                                                        >
                                                            <option>...</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <label htmlFor="dedicated_data_entry_staff" className="form-label text-uppercase form-site-in">
                                                            Does your site have dedicated data entry staff?
                                                        </label>
                                                        <select className='form-select' name='dedicated_data_entry_staff' id="dedicated_data_entry_staff"
                                                            value={dedicated_data_entry_staff} onChange={handleChange}
                                                        >
                                                            <option>...</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='py-3'>
                                                <button type='submit' className='btn-a'>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 