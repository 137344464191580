import React, { useContext, useState, useEffect } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

function EditCapabilities(props) {
    // eslint-disable-next-line no-unused-vars
    const [selectedCapabilities, setSelectedCapabilities] = useState([]);
    const [availableCapabilities, setAvailableCapabilities] = useState([]);
    const [myCapabilities, setMyCapabilities] = useState([]);

    const state = useContext(GlobalState);
    const [capabilitiesData] = state.capabilitiesApi.capabilitiesData;
    const [siteData] = state.siteData;
    const [callback, setCallback] = state.callback;
    const [, setAlert] = state.alert;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        setAvailableCapabilities([...capabilitiesData].sort((a, b) => a.name.localeCompare(b.name)));
        setMyCapabilities([...siteData.capabilities].sort((a, b) => a.name.localeCompare(b.name)));
    }, [capabilitiesData, siteData]);

    const handleCheckboxChange = async (capabilityId, isMyFacility) => {
        const selectedCapability = availableCapabilities.find((capability) => capability._id === capabilityId);

        if (isMyFacility) {
            const updatedFacilities = myCapabilities.filter((capability) => capability._id !== capabilityId);
            setMyCapabilities(updatedFacilities);

            try {
                await removeCapability(capabilityId);
            } catch (error) {
                handleApiError(error, 'Failed to remove capability.');
            }
        } else {
            const updatedFacilities = availableCapabilities.filter((capability) => capability._id !== capabilityId);
            setAvailableCapabilities(updatedFacilities);

            setMyCapabilities([...myCapabilities, selectedCapability]);

            try {
                await addCapability(capabilityId);
            } catch (error) {
                handleApiError(error, 'Failed to add capability.');
            }
        }
    };

    const handleApiError = (error, message) => {
        console.error('API Error:', error);
        setAlert({
            failure: true,
            success: false,
            failureMsg: message || "Failed to add facility",
            successMsg: '',
        });
        setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
    };

    const addCapability = async (capabilityId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/add-capabilities`, { capabilityId });
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                handleApiError(null, 'Failed to add capability. Unexpected status code: ' + response.status);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const removeCapability = async (capabilityId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/remove-capabilities`, { capabilityId });
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                handleApiError(null, 'Failed to remove capability. Unexpected status code: ' + response.status);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const filteredAvailableCapabilities = availableCapabilities.filter(
        (capability) => !myCapabilities.some((myCapability) => myCapability._id === capability._id)
    );

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Capabilities</h5>
                </div>

                <div className='row mb-3'>
                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Please select from this list all the capabilities that your site has</h6>
                                <p> <i>*To add a capability from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {filteredAvailableCapabilities.map((capability) => (
                                    <div className='col-lg-6 py-2' key={capability._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedCapabilities.includes(capability)}
                                                onChange={() => handleCheckboxChange(capability._id, false)}
                                                id={capability._id}
                                            />
                                            <label className="form-check-label" htmlFor={capability._id}>
                                                {capability.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Your site's Capabilities</h6>
                                <p> <i>*To remove a capability from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {myCapabilities.map((capability) => (
                                    <div className='col-lg-6 py-2' key={capability._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedCapabilities.includes(capability)}
                                                onChange={() => handleCheckboxChange(capability._id, true)}
                                                id={capability._id}
                                            />
                                            <label className="form-check-label" htmlFor={capability._id}>
                                                {capability.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditCapabilities;
