import React, { useContext } from "react";
import CSidebar from "../../components/Nav/CSidebar";
import Header from "../../components/Header/Header";
import { NavLink, Outlet } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function Site() {
   const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                <h6> {siteData.site_name} </h6>
                                <p> {siteData.city}, {siteData.state_province} </p>

                                <ul className="nav py-3">
                                    <li>
                                        <NavLink to={`/site`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Site Info
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/secondary-contact`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Secondary Contact
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/investigators`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Investigators
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/facilities`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Facilities
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/capabilities`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Capabilities
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/therapeutic-areas`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Therapeutic Areas
                                            </button>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/site/documents`} className={({ isActive, isPending, isTransitioning }) =>
                                            [
                                                isPending ? "pending" : "",
                                                isActive ? "active" : "",
                                                isTransitioning ? "transitioning" : "",
                                            ].join(" ")
                                        }>
                                            <button className="btn-b in-nav">
                                                <span className="me-2 fw-bold">  </span>
                                                Documents
                                            </button>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className="py-3">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}