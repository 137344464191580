import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import { GlobalState } from "../../GlobalState";

import Logo from '../../assets/images/logo.jpg'
import { AiOutlineLogin } from 'react-icons/ai'

const initialState = {
    password: "",
    cf_password: ""
}

export default function ResetPassword() {
    const [userDetails, setUserDetails] = useState(initialState);
    const { password, cf_password } = userDetails;

    const state = useContext(GlobalState);
    const [, setAlert] = state.alert;

    const [typePass, setTypePass] = useState(false)
    const [typeCfPass, setTypeCfPass] = useState(false)
    const [passError, setPassError] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const resetPassword = async (e) => {
        e.preventDefault()

        if (!password || !cf_password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }
        if (password !== cf_password) {
            setPassError(!passError)
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'Passwords do not match',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(
                `${API_ENDPOINT}/forget-password`,
                { ...userDetails },
                { withCredentials: true, credentials: 'include' }
            )
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            // console.log(res)
            // localStorage.setItem('firstLogin', true)
            window.location.href = "/login";
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <>
            <div className="auth-page">
                <div className="auth-form">
                    <div className="bg-white py-4 px-3">
                        <div className="text-center py-2">
                            <img src={Logo} alt="logo" className="auth-logo" />
                            <h6 className="auth-cap-txt">Enter your new password</h6>
                        </div>

                        <form onSubmit={resetPassword}>
                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="password" className="form-label text-uppercase fw-bold auth-label">Password</label>
                                    <input type={typePass ? "text" : "password"} id="password" name="password" className="form-control form-control-sm"
                                        value={password} onChange={handleChange}
                                    />

                                    <small onClick={() => setTypePass(!typePass)} >
                                        {typePass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="cf_password" className="form-label text-uppercase fw-bold auth-label">Confirm Password</label>
                                    <input type={typeCfPass ? "text" : "password"} id="cf_password" name="cf_password" className="form-control form-control-sm"
                                        value={cf_password} onChange={handleChange} />

                                    <small onClick={() => setTypeCfPass(!typeCfPass)} >
                                        {typeCfPass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>


                            <div className="d-flex justify-content-center pt-4 pb-3">
                                <button className="btn auth-action-btn" type="submit">
                                    reset password
                                    <span className="ms-2"><AiOutlineLogin /></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="bg-light pt-3 pb-2">
                        <p className="text-center auth-oth-link">Proceed to <Link to="/login">Sign in</Link></p>
                        <p className="text-center auth-oth-link">Back to <Link to="/">Home</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}