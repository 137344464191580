import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function TherapeuticAreas() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    const [therapeuticAreas, setTherapeuticAreas] = useState([]);

    useEffect(() => {
        setTherapeuticAreas([...siteData.therapeuticAreas])
    }, [siteData])

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Therapeutic Areas</h5>

                    <Link to="/site/therapeutic-areas/edit-therapeutic-areas"><button className="btn-d">Edit</button></Link>
                </div>

                <div className="bg-white p-3">
                    <div className="row">
                        {
                            therapeuticAreas.length === 0 ?
                                <>
                                    <h6>You haven't added any therapeutic area to your site!!!</h6>
                                </>
                                : <>
                                    {
                                        therapeuticAreas.map((therapeuticarea) =>
                                            <div className="col-lg-3 si-sec-b" key={therapeuticarea._id}>
                                                <li> {therapeuticarea.name} </li>
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}