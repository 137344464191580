import React, { useContext } from "react";
import CSidebar from "../../components/Nav/CSidebar";
import Header from "../../components/Header/Header";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MyStudyItem from "../../components/Studies/Mystudies/MyStudyItem";
import { GlobalState } from "../../GlobalState";
import { Link } from "react-router-dom";
import Nodata from '../../utils/Nodata'

export default function MyStudies() {
    const state = useContext(GlobalState);
    const [userData] = state.userData;
    const [studyInterestsData] = state.studyInterestsApi.studyInterestsData;

    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            {
                                userData.active_site === false
                                    ? <>
                                        <div className="card p-5 text-center m-5">
                                            <h6>Please registe your site on the portal to be able to use the portal fully!</h6>
                                            <Link className="mt-3" to="/new-site">
                                                <button className="btn-a">Add site</button>
                                            </Link>
                                        </div>
                                    </>
                                    : <>
                                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                            <h6>Hello, User!</h6>
                                            <p>Here are your studies.</p>
                                        </div>

                                        <div className="py-5">
                                            {
                                                studyInterestsData.length === 0 ?
                                                    <>
                                                        <Nodata />
                                                    </>
                                                    : <>
                                                        {
                                                            studyInterestsData.map(e =>
                                                                <MyStudyItem key={e._id} props={e} />
                                                            )
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}