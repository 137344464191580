import React from "react";
import { Link } from 'react-router-dom'

import { AiOutlineArrowRight } from 'react-icons/ai'

export default function MyStudyItem({ props }) {
    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4" >
                <div className="row study-item bg-white" style={{ boxShadow: '2px 3px 2px 2px #f1f1f1' }}>
                    <div className="col-lg-9 p-3" style={{ borderRight: '1px solid #333' }}>
                        <div className="d-flex justify-content-between flex-wrap flex-sm-wrap top mb-3">
                            <button className="btn-b"> unknown </button>

                            <div className="n"> {props && props.study.study_id ? `${props.study.study_id}` : "unknown"} </div>
                        </div>

                        <div className="">
                            <h5>
                                {props && props.study.study_title ? props.study.study_title : "Title not available"}
                                {/* Title */}
                            </h5>
                            <p>
                                {/* {props && props.study.study_description ? props.study.study_description : "Description not available"} */}
                                {/* Description */}
                                {props && props.study.study_description.length < 200
                                    ? props.study.study_description
                                    : "Description not available"}

                                {props && props.study.study_description.length > 200
                                    ? props.study.study_description.slice(0, 200) + ' .......'
                                    : "Description not available"}
                            </p>

                            <h6 className="text-danger">Submission Date: {props && props.study.deadline_for_submission ? props.study.deadline_for_submission : "Unknown"}</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 p-3">
                        <li>
                            <Link to={`/my-studies/${props && props._id ? props._id : ''}`}>
                                <button className="btn-b">
                                    See Details
                                    <span> <AiOutlineArrowRight /></span>
                                </button>
                            </Link>
                        </li>
                    </div>
                </div>
            </div>
        </>
    )
}
