import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'

// IMPORTED DATA APIs
import CapabilitiesAPI from './api/CapabilitiesAPI';
import FacilitiesAPI from './api/FacilitiesAPI';
import TherapeuticAreasAPI from './api/TherapeuticAreasAPI';
import StudiesAPI from './api/StudiesAPI';
import StudyInterestsAPI from './api/StudyInterestsAPI';
import SiteStaffsAPI from './api/SiteStaffsAPI';

// Loader
import Loader from './utils/Loader';


export const GlobalState = createContext()

export const DataProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [failureAlert, setFailureAlert] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [failureMsg, setFailureMsg] = useState('');
    const [alert, setAlert] = useState({
        success: false,
        failure: false,
        successMsg: '',
        failureMsg: '',
    });
    const [token, setToken] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    const [userData, setUserData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [callback, setCallback] = useState(false)

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    useEffect(() => {
        const firstLogin = localStorage.getItem('firstLogin')
        if (firstLogin) {
            const refreshToken = async () => {
                try {
                    // console.log('Refreshing token...');
                    const expirationTime = localStorage.getItem('expirationTime');
                    if (expirationTime && Date.now() < expirationTime) {
                        // Token is still valid, no need to refresh
                        setTimeout(() => {
                            refreshToken();
                        }, 15 * 60 * 1000); // Refresh token every 15 minutes
                        return;
                    }

                    const res = await axios.get(
                        `${API_ENDPOINT}/refresh_token`,
                        { withCredentials: true, credentials: 'include' }
                    );

                    setToken(res.data.access_token);
                    setIsLogged(true);
                    setUserData(res.data.user);
                    if (res.data.user.site) {
                        setSiteData(res.data.user.site)
                    }

                    // Update the expiration time
                    const newExpirationTime = Date.now() + res.data.expires_in * 1000;
                    localStorage.setItem('expirationTime', newExpirationTime);

                    setTimeout(() => {
                        refreshToken();
                    }, 15 * 60 * 1000); // Refresh token every 15 minutes
                } catch (err) {
                    console.log(err.response.data.msg);
                }
            };
            refreshToken();
        }
    }, [API_ENDPOINT, callback]);

    const state = {
        loading: [loading, setLoading],
        notify: [notify, setNotify],
        alert: [alert, setAlert],
        successAlert: [successAlert, setSuccessAlert],
        failureAlert: [failureAlert, setFailureAlert],
        successMsg: [successMsg, setSuccessMsg],
        failureMsg: [failureMsg, setFailureMsg],
        token: [token, setToken],
        isLogged: [isLogged, setIsLogged],
        userData: [userData, setUserData],
        siteData: [siteData, setSiteData],
        callback: [callback, setCallback],
        studiesApi: StudiesAPI(userData._id),
        facilitiesApi: FacilitiesAPI(),
        capabilitiesApi: CapabilitiesAPI(),
        therapeuticAreasApi: TherapeuticAreasAPI(),
        studyInterestsApi: StudyInterestsAPI(userData._id),
        siteStaffsApi: SiteStaffsAPI(siteData._id)
    }

    return (
        <GlobalState.Provider value={state}>
            {loading && <Loader />}
            {children}
        </GlobalState.Provider>
    )
}