import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../GlobalState";
import axios from "axios";

const initialState = {
    fullname: "",
    email: "",
    title: "",
    phone_number: "",
    cell_phone_number: "",
    cda_authorised_signatory: "",
    cta_authorised_signatory: "",
    budget_authorised_negotiator: "",
    regulatory_docs_responsility: "",
}

export default function EditProfile() {
    const [details, setDetails] = useState(initialState)
    const { fullname, email, title, phone_number, cell_phone_number, cda_authorised_signatory, cta_authorised_signatory,
        budget_authorised_negotiator, regulatory_docs_responsility } = details

    const state = useContext(GlobalState);
    const [userData] = state.userData;
    const [callback, setCallback] = state.callback;
    const [, setAlert] = state.alert;

    useEffect(() => {
        // Set the default values from userData
        setDetails((prevDetails) => ({
            ...prevDetails,
            fullname: userData.fullname || "",
            email: userData.email || "",
            title: userData.title || "",
            phone_number: userData.phone_number || "",
            cell_phone_number: userData.cell_phone_number || "",
            cda_authorised_signatory: userData.cda_authorised_signatory || false,
            cta_authorised_signatory: userData.cta_authorised_signatory || false,
            budget_authorised_negotiator: userData.budget_authorised_negotiator || false,
            regulatory_docs_responsility: userData.regulatory_docs_responsility || false,
        }));
    }, [userData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setDetails((prevDetails) => ({ ...prevDetails, [name]: checked }));
        } else {
            setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
        };
    };


    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const submitData = async (e) => {
        e.preventDefault();

        try {
            // Form validation
            if (!fullname) {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: 'One or more fields are empty',
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
                return;
            }

            const response = await axios.put(`${API_ENDPOINT}/users/${userData._id}`, { ...details });

            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            }
            setCallback(!callback)
        } catch (error) {
            console.error('Error submitting data:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <>
            <div className="">
                <div className="bg-white p-3 rounded-2">
                    <h6 className="fs-6 fw-600 mb-4">Edit Profile</h6>

                    <form onSubmit={submitData}>
                        <div className="mb-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label htmlFor="fullname" className="form-label">Full Name</label>
                                    <input type="text" className="form-control form-control-sm mb-3" id="fullname" name="fullname"
                                        value={fullname} onChange={handleChange}
                                    />
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control form-control-sm mb-3" id="email" name="email"
                                        value={email} onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control form-control-sm mb-3" id="title" name="title"
                                        value={title} onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label htmlFor="phone_number" className="form-label">Primary Phone Number</label>
                                    <input type="text" className="form-control form-control-sm mb-3" id="phone_number" name="phone_number"
                                        value={phone_number} onChange={handleChange}
                                    />
                                </div>

                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <label htmlFor="cell_phone_number" className="form-label">Cell Phone Number (Optional)</label>
                                    <input type="text" className="form-control form-control-sm mb-3" id="cell_phone_number" name="cell_phone_number"
                                        value={cell_phone_number} onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <h6>Check all that applies</h6>

                            <div className="col-lg-12 d-flex mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check form-check-input me-2"
                                    id="cda_authorised_signatory"
                                    name="cda_authorised_signatory"
                                    value={cda_authorised_signatory}
                                    onChange={handleChange}
                                    checked={details.cda_authorised_signatory}
                                />
                                <label className="form-label" style={{ fontSize: '13px', fontWeight: 600 }}>
                                    Authorised Signatory for CDA (Confidentialify Disclosure Agreement)
                                </label>
                            </div>

                            <div className="col-lg-12 d-flex mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check form-check-input me-2"
                                    id="cta_authorised_signatory"
                                    name="cta_authorised_signatory"
                                    value={cta_authorised_signatory}
                                    onChange={handleChange}
                                    checked={details.cta_authorised_signatory}
                                />
                                <label className="form-label" style={{ fontSize: '13px', fontWeight: 600 }}>
                                    Authorised Signatory for CTA (Clinical Trial Agreement)
                                </label>
                            </div>

                            <div className="col-lg-12 d-flex mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check form-check-input me-2"
                                    id="budget_authorised_negotiator"
                                    name="budget_authorised_negotiator"
                                    value={budget_authorised_negotiator}
                                    onChange={handleChange}
                                    checked={details.budget_authorised_negotiator}
                                />
                                <label className="form-label" style={{ fontSize: '13px', fontWeight: 600 }}>
                                    Authorised to negotiate contracts and budgets
                                </label>
                            </div>

                            <div className="col-lg-12 d-flex mb-3">
                                <input
                                    type="checkbox"
                                    className="form-check form-check-input me-2"
                                    id="regulatory_docs_responsility"
                                    name="regulatory_docs_responsility"
                                    value={regulatory_docs_responsility}
                                    onChange={handleChange}
                                    checked={details.regulatory_docs_responsility}
                                />
                                <label className="form-label" style={{ fontSize: '13px', fontWeight: 600 }}>
                                    Responsible for completing regulatory documents
                                </label>
                            </div>
                        </div>
                        <div className="d-flex">
                            <button className="btn-d me-2">Cancel</button>
                            <button type="submit" className="btn-a">Save Changes</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}