import React, { useContext } from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
    // CDBSidebarCTA
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { GlobalState } from '../../GlobalState';

import Logo from '../../assets/images/phoenixicon.png'

const CSidebar = () => {
    const state = useContext(GlobalState);
    const [userData] = state.userData;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const logoutUser = () => {
        try {
            axios.post(`${API_ENDPOINT}/logout`)
            localStorage.clear()
            alert("User logged out")
            window.location.href = "/";
        } catch (error) {
            alert(error.response.msg)
        }
    }

    return (
        <div
            style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}
        >
            <CDBSidebar textColor="#fff" backgroundColor="#2980AB">
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
                    <a
                        href="/"
                        className="text-decoration-none"
                        style={{ color: 'inherit' }}
                    >
                        <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={Logo}
                                alt=""
                                style={{ width: '30px' }}
                            />
                            <h6 className="ms-1">PHOENIX TMG™</h6>
                        </div>
                    </a>
                </CDBSidebarHeader>
                {
                    userData.active_site === false
                        ? <>
                            <CDBSidebarContent>
                                <CDBSidebarMenu>
                                    <NavLink exact="true" to="#" onClick={logoutUser} activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="exclamation-circle">
                                            Logout
                                        </CDBSidebarMenuItem>
                                    </NavLink>
                                </CDBSidebarMenu>
                            </CDBSidebarContent>
                        </>
                        : <>

                            <CDBSidebarContent className="sidebar-content">
                                <CDBSidebarMenu>
                                    <NavLink exact="true" to="/" activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="columns">My Studies</CDBSidebarMenuItem>
                                    </NavLink>
                                    <NavLink exact="true" to="/discover-studies" activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="table">Discover</CDBSidebarMenuItem>
                                    </NavLink>
                                    <NavLink exact="true" to="/site" activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="chart-line">
                                            My Site
                                        </CDBSidebarMenuItem>
                                    </NavLink>
                                    <NavLink exact="true" to="/site-staffs" activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="chart-line">
                                            Site Staffs
                                        </CDBSidebarMenuItem>
                                    </NavLink>
                                    <NavLink exact="true" to="/settings" activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="user">My Account</CDBSidebarMenuItem>
                                    </NavLink>
                                    <NavLink exact="true" to="#" onClick={logoutUser} activeclassname="activeClicked">
                                        <CDBSidebarMenuItem icon="exclamation-circle">
                                            Logout
                                        </CDBSidebarMenuItem>
                                    </NavLink>
                                </CDBSidebarMenu>
                            </CDBSidebarContent>
                        </>
                }
                <CDBSidebarFooter style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            padding: '20px 5px',
                        }}
                    >
                        For support, please contact <br /> support@phoenixtmg.com
                    </div>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};
export default CSidebar;