// import React, { useContext } from "react";

// import CSidebar from "../../components/Nav/CSidebar";
// import Header from "../../components/Header/Header";

// import StudyItem from "../../components/Studies/DiscoverStudies/StudyItem";
// import { GlobalState } from "../../GlobalState";
// import Nodata from "../../utils/Nodata";

// export default function DiscoverStudies() {
//     const state = useContext(GlobalState);
//     const [studiesData] = state.studiesApi.studiesData;
//     const [error] = state.studiesApi.error; // Get the error message from the state

//     return (
//         <>
//             <div className="d-flex profile">
//                 <div>
//                     <CSidebar />
//                 </div>
//                 <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
//                     <Header />

//                     <div className="bg-light" style={{ height: "100%" }}>
//                         <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
//                             {error && ( // Check if error exists, if so, display error message
//                                 <div className="alert alert-danger" role="alert">
//                                     {error}
//                                 </div>
//                             )}

//                             {studiesData.length === 0 ? (
//                                 <Nodata />
//                             ) : (
//                                 <>
//                                     <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
//                                         <h6>Hello, Guest!</h6>
//                                         <p>Here are a list of studies, we have for you today.</p>
//                                     </div>

//                                     <div className="studies-list py-5">
//                                         {studiesData.map((study) => (
//                                             <StudyItem key={study._id} study={study} />
//                                         ))}
//                                     </div>
//                                 </>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }


import React, { useContext } from "react";

import CSidebar from "../../components/Nav/CSidebar";
import Header from "../../components/Header/Header";

import StudyItem from "../../components/Studies/DiscoverStudies/StudyItem";
import { GlobalState } from "../../GlobalState";
import Nodata from "../../utils/Nodata";

export default function DiscoverStudies() {
    const state = useContext(GlobalState);
    const [studiesData] = state.studiesApi.studiesData;
    const [error] = state.studiesApi.error; // Get the error message from the state

    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            {error && ( // Check if error exists, if so, display error message
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )}

                            {!error && ( // Render other content only if there's no error
                                <>
                                    {
                                        studiesData.length === 0 ? (
                                            <Nodata />
                                        ) : (
                                            <>
                                                <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                                    <h6>Hello, Guest!</h6>
                                                    <p>Here are a list of studies, we have for you today.</p>
                                                </div>

                                                <div className="studies-list py-5">
                                                    {studiesData.map((study) => (
                                                        <StudyItem key={study._id} study={study} />
                                                    ))}
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
