import React from "react";

import CSidebar from "../../components/Nav/CSidebar";
import Header from "../../components/Header/Header";
import EditProfile from "./EditProfile";

export default function Settings() {

    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div style={{ height: "100%" }} className="bg-light">
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                <h5>Settings</h5>
                                <p>Manage your account</p>
                            </div>

                            <div className="">
                                <div className="container py-5">
                                    <EditProfile />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}