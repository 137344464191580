import React from "react";
import { Link } from "react-router-dom";
import CompanyIMG from '../assets/images/phx-trans.png'

export default function Home() {
    return (
        <>
            <div className="d-flex" style={{ height: '100vh' }}>
                <div className="col-lg-8 bg-light d-flex justify-content-center align-items-center">
                    <div className="home-banner-caption text-start p-5">
                        <img src={CompanyIMG} alt="company-logo" className="mb-3" style={{ maxHeight: '100px', marginLeft: '-365px' }} />
                        <h1>
                            What we <span className="a-col">do</span>
                            <br />
                            We help you find the perfect <span className="b-col">study</span>
                        </h1>
                        <p className="mt-4 pe-5">
                            At Phoenix Trail Management Group, We're not just a clinical Trial management organization - we're a catalyst for growth in healthcare.
                            We're commited to driving excellence and propelling advancement in clinical research.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 bg-secondary d-flex justify-content-center align-items-center gts">
                    <div className="">
                        <h6 className="text-center text-white fs-4 mb-4">Get Started with us</h6>
                        <div className="d-flex justify-content-center mt-3">
                            <Link to="/login">
                                <button className="btn-a me-2">Sign in</button>
                            </Link>
                            <Link to="/register">
                                <button className="btn-d">Register with us</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}