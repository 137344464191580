import { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalState } from './GlobalState';

// UTILS
import Alert from './utils/Alert';
import Loader from './utils/Loader';

// HOME
import Home from './pages/Home';

// Auth Pages
import Login from './pages/auth/Login';
import Register from "./pages/auth/Register";
import ActivateEmail from './pages/auth/ActivateEmail';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';

// STUDY PAGES
import MyStudies from "./pages/Studies/MyStudies";
import DiscoverStudies from "./pages/Studies/DiscoverStudies";
import DiscoverStudyDetails from './pages/Studies/discoverStudies/StudyDetails';
import MyStudyDetails from './pages/Studies/mystudies/MyStudyDetails';

// NEW SITE
import NewSite from './pages/Site/NewSite';

// SITE DETAILS
import SiteInformation from './pages/Site/Site';
import SecondaryContact from './pages/Site/SecondaryContact';
import PrincipalInvestigators from './pages/Site/PrincipalInvestigators';
import SiteFacilities from './pages/Site/SiteFacilities';
import SiteCapabilities from './pages/Site/SiteCapabilities';
import TherapeuticAreas from './pages/Site/TherapeuticAreas';
import Docs from './pages/Site/Docs';
import SiteInfo from './pages/Site/SiteInfoHeader';

// SITE SETTINGS
import SecContactForm from './pages/Site/Forms/SecContactForm';
import DocUpload from './pages/Site/Forms/DocUpload';
import InvestigatorsForm from './pages/Site/Forms/InvestigatorsForm';
import EditFacilities from './pages/Site/Forms/EditFacilities';
import EditCapabilities from './pages/Site/Forms/EditCapabilities';
import EditTherapeuticAreas from './pages/Site/Forms/EditTherapeuticAreas';
import EditSiteInfo from './pages/Site/Forms/EditSiteInfo';

// SITE STAFFS
import SiteStaffs from './pages/siteStaffs/SiteStaffs';

// MY PROFILE
import Settings from './pages/settings/Settings';

function App() {
  const state = useContext(GlobalState)
  const [isLogged] = state.isLogged;
  const [loading] = state.loading;

  return (
    <Router>
       {loading && <Loader />}
      <Alert />

      <div className="App">
        <main className="App-body">
          <Routes>
            <Route path="/" element={isLogged ? <MyStudies /> : <Home />} />

            {/* AUTH PAGES */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email/:activation_token" element={<ActivateEmail />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* =====================USER PAGES================  */}
            {/* STUDY PAGES */}
            <Route path="/my-studies" element={isLogged ? <MyStudies /> : <Login />} />
            <Route path="/discover-studies" element={isLogged ? <DiscoverStudies /> : <Login />} />
            <Route path="/my-studies/:id" element={isLogged ? <MyStudyDetails /> : <Login />} />
            <Route path="/discover-studies/:id" element={isLogged ? <DiscoverStudyDetails /> : <Login />} />

            {/* SITE REG PAGE */}
            <Route path="/new-site" element={isLogged ? <NewSite /> : <Login />} />

            {/* SETTINGS PAGES */}
            <Route path="/settings" element={isLogged ? <Settings /> : <Login />} />

            {/* SITE STAFF PAGES */}
            <Route path="/site-staffs" element={isLogged ? <SiteStaffs /> : <Login />}>
              {/* <Route path="/sites" element={isLogged ? <Sites /> : <Login />} /> */}
            </Route>

            {/* SITE INFORMATION PAGES */}
            <Route path="/site/" element={isLogged ? <SiteInformation /> : <Login />} >
              <Route path="/site/" element={isLogged ? <SiteInfo /> : <Login />} />
              <Route path="/site/secondary-contact" element={isLogged ? <SecondaryContact /> : <Login />} />
              <Route path="/site/investigators" element={isLogged ? <PrincipalInvestigators /> : <Login />} />
              <Route path="/site/facilities" element={isLogged ? <SiteFacilities /> : <Login />} />
              <Route path="/site/capabilities" element={isLogged ? <SiteCapabilities /> : <Login />} />
              <Route path="/site/therapeutic-areas" element={isLogged ? <TherapeuticAreas /> : <Login />} />
              <Route path="/site/documents" element={isLogged ? <Docs /> : <Login />} />
              {/* FORM PAGES */}
              <Route path="/site/edit-site" element={isLogged ? <EditSiteInfo /> : <Login />} />
              <Route path="/site/secondary-contact/edit-contact" element={isLogged ? <SecContactForm /> : <Login />} />
              <Route path="/site/documents/upload" element={isLogged ? <DocUpload /> : <Login />} />
              <Route path="/site/investigators/add-investigator" element={isLogged ? <InvestigatorsForm /> : <Login />} />
              <Route path="/site/facilities/edit-facilities" element={isLogged ? <EditFacilities /> : <Login />} />
              <Route path="/site/capabilities/edit-capabilities" element={isLogged ? <EditCapabilities /> : <Login />} />
              <Route path="/site/therapeutic-areas/edit-therapeutic-areas" element={isLogged ? <EditTherapeuticAreas /> : <Login />} />
            </Route>

            {/* STAFF INVITE PAGES */}
            {/* <Route path="/settings/invite-staff" element={isLogged ? <InviteStaff /> : <Login />} /> */}

            {/* =====================COMPANY PAGES================  */}
            {/* STAFF STUDY PAGES */}
            {/* <Route path='/studies' element={isLogged ? <Studies /> : <Login />}>
              <Route path="/studies" element={isLogged ? <StudiesList /> : <Login />} />
              <Route path="/studies/new-study" element={isLogged ? <NewStudy /> : <Login />} />
            </Route> */}

            {/* STAFF SITES PAGES */}
            {/* <Route path='/sites' element={isLogged ? <Sites /> : <Login />}>
              <Route path="/sites" element={isLogged ? <SitesList /> : <Login />} />
            </Route> */}


            {/* ===================== IT-ADMIN PAGES ========================= */}
            {/* <Route path='/admin/users' element={isLogged ? <Users /> : <Login />}></Route> */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
