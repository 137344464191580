import React, { useContext, useEffect, useState } from "react";
import CSidebar from "../../../components/Nav/CSidebar";
import Header from "../../../components/Header/Header";
import { Parser } from 'html-to-react'
import { TiArrowBack } from "react-icons/ti";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { GlobalState } from "../../../GlobalState";
import { TiArrowMaximise } from "react-icons/ti";
import { FaDownload } from "react-icons/fa";

export default function MyStudyDetails() {
    const state = useContext(GlobalState);
    // eslint-disable-next-line no-unused-vars
    const [userData] = state.userData;
    // eslint-disable-next-line no-unused-vars
    const [siteData] = state.siteData;
    // const [studiesData] = state.studiesApi.studiesData;
    // eslint-disable-next-line no-unused-vars
    const [, setAlert] = state.alert;

    const { id } = useParams();
    const [intData, setIntData] = useState(null)

    const [uploadedFilesFQ, setUploadedFilesFQ] = useState([]);
    const [uploadedFilesCDA, setUploadedFilesCDA] = useState([]);
    const [uploadProgressFQ, setUploadProgressFQ] = useState(0);
    const [uploadProgressCDA, setUploadProgressCDA] = useState(0);
    const [uploadStatusFQ, setUploadStatusFQ] = useState('');
    const [uploadStatusCDA, setUploadStatusCDA] = useState('');

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    useEffect(() => {
        if (id) {
            const getData = async () => {
                try {
                    let res = await axios.get(`${API_ENDPOINT}/study-interests/${id}`);
                    // console.log(res)
                    setIntData(res.data.item);
                } catch (error) {
                    console.error("Error fetching study data:", error);
                }
            }
            getData()
        }
    }, [id, API_ENDPOINT])

    // Download Feasibility Questionnaire
    const downloadFQDocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    };

    // Download Client Disclosure Agreement
    const downloadCDADocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    // Download Study Document
    const downloadDocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    const submitFQDoc = async (event) => {
        try {
            const formData = new FormData();
            const files = event.target.files;

            if (files.length > 0) {
                for (const file of files) {
                    formData.append("files", file);
                }

                const uploadRes = await axios.post(
                    `${API_ENDPOINT}/studyFQDocs`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            setUploadProgressFQ(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                    }
                );

                const filesResponse = uploadRes.data.files;
                setUploadedFilesFQ(filesResponse);
                setUploadStatusFQ('success');

                // Make additional API call with upload response
                const additionalAPICallRes = await axios.put(
                    `${API_ENDPOINT}/study-interests/${id}/fqdoc`,
                    { fqdocURL: filesResponse }, // Assuming 'filesResponse' contains the URL
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                // Handle additional API call response if needed
                if (additionalAPICallRes.status === 200) {
                    setAlert({
                        failure: false,
                        success: true,
                        failureMsg: additionalAPICallRes.data.msg,
                        successMsg: '',
                    });
                    setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                    window.location.reload();
                }

                // Clear upload status message after 2 seconds
                setTimeout(() => {
                    setUploadStatusFQ('');
                }, 2000);
            }
        } catch (error) {
            console.error("Error uploading FQDoc:", error);
            setUploadStatusFQ('failure');
            // Handle errors if any
        }
    };


    const submitCDADoc = async (event) => {
        try {
            const formData = new FormData();
            const files = event.target.files;

            if (files.length > 0) {
                for (const file of files) {
                    formData.append("files", file);
                }

                const uploadRes = await axios.post(
                    `${API_ENDPOINT}/studyCDADocs`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            setUploadProgressCDA(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                    }
                );

                const filesResponse = uploadRes.data.files;
                setUploadedFilesCDA(filesResponse);
                setUploadStatusCDA('success');

                // Clear upload status message after 2 seconds
                // setTimeout(() => {
                //     uploadStatusCDA('');
                // }, 2000);

                // Make additional API call with upload response
                const additionalAPICallRes = await axios.put(
                    `${API_ENDPOINT}/study-interests/${id}/cda`,
                    { cdaURL: filesResponse }, // Assuming 'filesResponse' contains the URL
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                // Handle additional API call response if needed
                if (additionalAPICallRes.status === 200) {
                    setAlert({
                        failure: false,
                        success: true,
                        failureMsg: additionalAPICallRes.data.msg,
                        successMsg: '',
                    });
                    setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                    window.location.reload();
                }

                // Clear upload status message after 2 seconds
                setTimeout(() => {
                    setUploadStatusFQ('');
                }, 2000);
            }
        } catch (error) {
            console.error("Error uploading CDADoc:", error);
            setUploadStatusCDA('failure');
            // Handle errors if any
        }
    };

    if (!intData) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <>
            <>
                <div className="d-flex profile">
                    <div>
                        <CSidebar />
                    </div>
                    <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                        <Header />

                        <div className="bg-light" style={{ height: "100%" }}>
                            <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                                <div className="mb-4">
                                    <Link to="/my-studies">
                                        <button className="btn btn-sm btn-outline-danger">
                                            <span className="me-2"><TiArrowBack /></span>
                                            Back
                                        </button>
                                    </Link>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row bg-white py-3 mb-4">
                                            <div className="col-lg-4">
                                                <p>
                                                    <small className="d-block"> REF NO:</small>
                                                    <b> {intData.study && intData.study.study_id ? `${intData.study.study_id}` : "unknown"} </b>
                                                </p>
                                                <p>
                                                    <small className="d-block"> Submission Date:</small>
                                                    <b>
                                                        {intData.study && intData.study.deadline_for_submission ? `${intData.study.deadline_for_submission}` : "unknown"}
                                                    </b>
                                                </p>
                                                <p>
                                                    <small className="d-block"> PhoenixTMG Contact:</small>
                                                    <button className="btn btn-sm btn-outline-secondary mt-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                                        <i>
                                                            {intData.study && intData.study.staff.firstname ? `${intData.study.staff.firstname} ` : "unknown"}
                                                            {intData.study && intData.study.staff.lastname ? `${intData.study.staff.lastname}` : "unknown"}
                                                        </i>
                                                        <span className="ms-2 fw-bold">
                                                            <TiArrowMaximise />
                                                        </span>
                                                    </button>
                                                </p>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                                                    <button className="btn btn-outline-secondary me-2" type="button">
                                                        Awarded with CRO
                                                    </button>
                                                    {/* <button type="button" className="btn-a"
                                                // onClick={openConfirmationModal} 
                                                >
                                                    Join Waitlist
                                                </button> */}
                                                </div>
                                                <h5 className="mb-3"> {intData.study && intData.study.study_title ? `${intData.study.study_title}` : "unknown"} </h5>

                                                <p className="mb-3" style={{ fontSize: '14px' }}>
                                                    {intData.study && intData.study.study_description ? `${intData.study.study_description}` : "unknown"}
                                                </p>
                                            </div>
                                        </div>

                                        {/* study state */}
                                        <div className="row mb-3">
                                            <div className="col-lg-12 d-flex justify-content-between bg-white rounded-2 p-3">
                                                <li
                                                    style={{ color: intData.joinedWaitlist ? 'red' : 'inherit' }}
                                                >1 Waitlist Joined</li>
                                                <li
                                                    style={{ color: intData.completedFeasibilityQuestionnaire ? 'red' : 'inherit' }}
                                                >
                                                    2 FQ Completed
                                                </li>
                                                <li
                                                    style={{ color: intData.completedClientDisclosureAgreement ? 'red' : 'inherit' }}
                                                >
                                                    3 CDA Completed
                                                </li>
                                                <li
                                                // style={{ color: intData.joinedWaitlist ? 'red' : 'inherit' }}
                                                >
                                                    4 Under Review
                                                </li>
                                                <li
                                                // style={{ color: intData.joinedWaitlist ? 'red' : 'inherit' }}
                                                >
                                                    5 Sponsor Decision
                                                </li>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 bg-white py-3">
                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active mb-3" id="pills-keyInfo-tab" data-bs-toggle="pill" data-bs-target="#pills-keyInfo" type="button" role="tab" aria-controls="pills-keyInfo" aria-selected="true">
                                                            Key Information
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-inclusionCriteria-tab" data-bs-toggle="pill" data-bs-target="#pills-inclusionCriteria" type="button" role="tab" aria-controls="pills-inclusionCriteria" aria-selected="false">
                                                            Inclusion Criteria
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-exclusionCriteria-tab" data-bs-toggle="pill" data-bs-target="#pills-exclusionCriteria" type="button" role="tab" aria-controls="pills-exclusionCriteria" aria-selected="false">
                                                            Exclusion Criteria
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-studyTimeline-tab" data-bs-toggle="pill" data-bs-target="#pills-studyTimeline" type="button" role="tab" aria-controls="pills-studyTimeline" aria-selected="false">
                                                            Study Timeline
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-equipmentNeeded-tab" data-bs-toggle="pill" data-bs-target="#pills-equipmentNeeded" type="button" role="tab" aria-controls="pills-equipmentNeeded" aria-selected="false" >
                                                            Equipment Needed
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-studyDocs-tab" data-bs-toggle="pill" data-bs-target="#pills-studyDocs" type="button" role="tab" aria-controls="pills-studyDocs" aria-selected="false" >
                                                            Study Documents
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link mb-3" id="pills-additionalInfo-tab" data-bs-toggle="pill" data-bs-target="#pills-additionalInfo" type="button" role="tab" aria-controls="pills-additionalInfo" aria-selected="false" >
                                                            Additional Information
                                                        </button>
                                                    </li>
                                                </ul>

                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-keyInfo" role="tabpanel" aria-labelledby="pills-keyInfo-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">key Information</h6>

                                                            <ul className="list-group">
                                                                <li>
                                                                    Indication:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.indications ? `${intData.study.indications}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    CRO Name:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.cro_name ? `${intData.study.cro_name}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Sponsor Name:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.sponsor_name ? `${intData.study.sponsor_name}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Protocol Number:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.protocol_number ? `${intData.study.protocol_number}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Total Number of Sites:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.total_number_of_sites ? `${intData.study.total_number_of_sites}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Preferred PI Specialty/Specialties:intData.
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.preffered_investigator_specialty ? `${intData.study.preffered_investigator_specialty}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Experience:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.experience ? `${intData.study.experience}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-inclusionCriteria" role="tabpanel" aria-labelledby="pills-inclusionCriteria-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Inclusion Criteria</h6>

                                                            {
                                                                intData.study && intData.study.inclusionCriteria ?
                                                                    <>
                                                                        <div>
                                                                            {Parser().parse(intData.study.inclusionCriteria)}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Information will be provided when available.</p>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-exclusionCriteria" role="tabpanel" aria-labelledby="pills-exclusionCriteria-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Exclusion Criteria</h6>

                                                            {
                                                                intData.study && intData.study.exclusionCriteria ?
                                                                    <>
                                                                        <div>
                                                                            {Parser().parse(intData.study.exclusionCriteria)}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Information will be provided when available.</p>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-studyTimeline" role="tabpanel" aria-labelledby="pills-studyTimeline-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Study Timeline</h6>

                                                            <ul className="list-group">
                                                                <li>
                                                                    Start Date:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.start_date ? `${intData.study.start_date}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Enrollment Period:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.enrollment_period ? `${intData.study.enrollment_period}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Study Duration:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.study_duration ? `${intData.study.study_duration}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Deadline for Submission:
                                                                    <span className="fw-bold ms-2">
                                                                        {intData.study && intData.study.deadline_for_submission ? `${intData.study.deadline_for_submission}` : "unknown"}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-equipmentNeeded" role="tabpanel" aria-labelledby="pills-equipmentNeeded-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Equipment Needed</h6>

                                                            {
                                                                intData.study && intData.study.equipmentNeeded ?
                                                                    <>
                                                                        <div>
                                                                            {Parser().parse(intData.study.equipmentNeeded)}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Information will be provided when available.</p>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-studyDocs" role="tabpanel" aria-labelledby="pills-studyDocs-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Study Documents</h6>

                                                            {intData.study && intData.study.study_documents && intData.study.study_documents.length === 0 ? (
                                                                <div>
                                                                    <p>Study documents will be provided when available.</p>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {intData.study && intData.study.study_documents && (
                                                                        <ul>
                                                                            {intData.study.study_documents.map((e, i) => (
                                                                                <li key={i} className="d-flex justify-content-between">
                                                                                    {e.name}

                                                                                    <span>
                                                                                        <button className="btn btn-sm btn-dark"
                                                                                            onClick={() => downloadDocument(e.url)}
                                                                                        >
                                                                                            <FaDownload />
                                                                                        </button>
                                                                                    </span>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="pills-additionalInfo" role="tabpanel" aria-labelledby="pills-additionalInfo-tab" tabIndex="0">
                                                        <div className="card p-3">
                                                            <h6 className="d-inline-block">Additional Information</h6>

                                                            {
                                                                intData.study && intData.study.additionalInfo ?
                                                                    <>
                                                                        <div>
                                                                            {Parser().parse(intData.study.additionalInfo)}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Information will be provided when available.</p>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 px-4">
                                        <div className="row mb-3">
                                            <div className="bg-white p-3 mb-3">
                                                <h6>Actions</h6>
                                            </div>
                                            <div className="col-lg-12 bg-white mb-3 py-2">
                                                <div className="">
                                                    <div className="mb-3">
                                                        <h6 className="pb-2" style={{ fontSize: '14px', fontWeight: '500', borderBottom: "1px solid #000" }}>Download Feasilibility Questionaire</h6>

                                                        {
                                                            intData.study.feasibilityQuestionnaire.length === 0 ? (
                                                                "Feasibility Questionnaire not available yet"
                                                            ) : (
                                                                intData.study.feasibilityQuestionnaire.map((e, i) => (
                                                                    <li className="d-flex justify-content-between" key={i}>
                                                                        <span> {e.name} </span>
                                                                        <button
                                                                            className="btn btn-sm btn-dark"
                                                                            style={{ fontSize: '12px' }}
                                                                            onClick={() => downloadFQDocument(e.url)}
                                                                        >
                                                                            <span><FaDownload /></span>
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        intData.completedFeasibilityQuestionnaire &&
                                                        <>
                                                            <div className="mb-3">
                                                                <h6 className="pb-2" style={{ fontSize: '14px', fontWeight: '500', borderBottom: "1px solid #000" }}>Download Client Disclosure Agreement</h6>

                                                                {
                                                                    intData.study.clientDisclosureAgreement.length === 0 ?
                                                                        "Client Disclosure Agreement not available yet"
                                                                        : <>
                                                                            {
                                                                                intData.study.clientDisclosureAgreement.map((e, i) =>
                                                                                    <li className="d-flex justify-content-between">
                                                                                        <span> {e.name} </span>
                                                                                        <button className="btn btn-sm btn-dark" style={{ fontSize: '12px' }}
                                                                                            onClick={() => downloadCDADocument(e.url)}
                                                                                        >
                                                                                            <span><FaDownload /></span>
                                                                                        </button>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-lg-12 bg-white p-3">
                                                <div className="">
                                                    <div className="col-lg-12 bg-white mb-3 py-2">
                                                        <>
                                                            {
                                                                intData.completedFeasibilityQuestionnaire ?
                                                                    <>
                                                                        <div className="pb-3">
                                                                            <h6
                                                                                className="pb-2" style={{ fontSize: '14px', fontWeight: '500', borderBottom: "1px solid #000" }}
                                                                            >Feasibility Questionnaire</h6>

                                                                            <div className="mt-3">
                                                                                {
                                                                                    intData.feasibilityQuestionnaireDocument.map((e, i) =>
                                                                                        <li key={i}>
                                                                                            {e.name}
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : <>
                                                                        <label className="form-label" htmlFor="fq">Feasibility Questionnaire (FQ) </label>
                                                                        <input type="file" className="form-control form-control-md"
                                                                            id="fqFiles"
                                                                            onChange={submitFQDoc}
                                                                            multiple
                                                                        />
                                                                        <div className="progress my-3">
                                                                            <div className={`progress-bar ${uploadStatusFQ === 'success' ? 'bg-success' : uploadStatusFQ === 'failure' ? 'bg-danger' : ''}`} role="progressbar" style={{ width: `${uploadProgressFQ}%` }} aria-valuenow={uploadProgressFQ} aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <div className="mt-1">{uploadStatusFQ === 'success' ? 'Upload Successful' : uploadStatusFQ === 'failure' ? 'Upload Failed' : ''}</div>
                                                                        <div className="mt-3">
                                                                            <p>Uploaded Feasilibility Questionaire:</p>
                                                                            <ul>
                                                                                {uploadedFilesFQ.map((file, index) => (
                                                                                    <li key={index}>{file.name}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </>
                                                    </div>

                                                    {
                                                        intData.completedFeasibilityQuestionnaire && intData.completedClientDisclosureAgreement ?
                                                            <>
                                                                <>
                                                                    <div className="pb-3">
                                                                        <h6
                                                                            className="pb-2" style={{ fontSize: '14px', fontWeight: '500', borderBottom: "1px solid #000" }}
                                                                        >Client Disclosure Agreement</h6>

                                                                        <div className="mt-3">
                                                                            {
                                                                                intData.clientDisclosureAgreementDocument.map((e, i) =>
                                                                                    <li key={i}>
                                                                                        {e.name}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </>
                                                            : <>
                                                                {
                                                                    intData.completedFeasibilityQuestionnaire &&
                                                                    <>
                                                                        <div className="col-lg-12 bg-white p-2 border">
                                                                            <label className="form-label" htmlFor="cda">Client Disclosure Agreement (CDA)</label>
                                                                            <input type="file" className="form-control form-control-md"
                                                                                id="cdaFiles"
                                                                                onChange={submitCDADoc}
                                                                                multiple
                                                                            />
                                                                            <div className="progress my-3">
                                                                                <div className={`progress-bar ${uploadStatusCDA === 'success' ? 'bg-success' : uploadStatusCDA === 'failure' ? 'bg-danger' : ''}`} role="progressbar" style={{ width: `${uploadProgressCDA}%` }} aria-valuenow={uploadProgressCDA} aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                            <div className="mt-1">{uploadStatusCDA === 'success' ? 'Upload Successful' : uploadStatusCDA === 'failure' ? 'Upload Failed' : ''}</div>
                                                                            <div className="mt-3">
                                                                                <p>Uploaded CDADoc Files:</p>
                                                                                <ul>
                                                                                    {uploadedFilesCDA.map((file, index) => (
                                                                                        <li key={index}>{file.name}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* PHOENIXTMG CONTACT */}
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <div className="">
                            <small>PhoenixTMG Contact</small>
                            <h5 className="offcanvas-title" id="offcanvasRightLabel">
                                {intData.study && intData.study.staff.firstname ? `${intData.study.staff.firstname} ` : "unknown"}
                                {intData.study && intData.study.staff.lastname ? `${intData.study.staff.lastname} ` : "unknown"}
                            </h5>
                        </div>
                        <pp0l className="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></pp0l>
                    </div>
                    <div className="offcanvas-body">
                        <small className="pb-3 fs-6">Contact Information</small>
                        <ul className="list-group mt-3">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Email</div>
                                    {intData.study && intData.study.staff.email ? `${intData.study.staff.email}` : "unknown"}
                                </div>
                                {/* <span class="badge bg-primary rounded-pill">14</span> */}
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Phone number</div>
                                    {intData.study && intData.study.staff.phonenumber ? `${intData.study.staff.phonenumber}` : "unknown"}
                                </div>
                                {/* <span class="badge bg-primary rounded-pill">14</span> */}
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Position</div>
                                    {intData.study && intData.study.staff.phonenumber ? `${intData.study.staff.phonenumber}` : "unknown"}
                                </div>
                                {/* <span class="badge bg-primary rounded-pill">14</span> */}
                            </li>
                        </ul>
                    </div>
                </div>

            </>
        </>
    )
}