import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function Docs() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Documents Uploaded</h5>

                    <Link to="/site/documents/upload">
                        <button className="btn-d"> Upload </button>
                    </Link>
                </div>

                <div className="p-3">
                    <div className="row">
                        <div className="bg-light p-3 rounded-2" style={{ boxShadow: '4px 5px 3px 2px #f1f1f1' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
                                <symbol id="check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </symbol>
                            </svg>
                            <div>
                                <div className="mb-3">
                                    List of files to be uploaded
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">1. CDA</div>
                                    <div className="col-lg-4">2. Feasibility Questionaire</div>
                                    <div className="col-lg-4">3. Site</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="list-group list-group-numbered p-3">
                        {
                            siteData.documents.length === 0 ?
                                <div className="p-5 text-center">
                                    <h6>No Document uploaded yet, please upload all necessary documents</h6>
                                    <span>
                                        <i>All documents are to be uploaded in <b>PDF</b> format</i>
                                    </span>
                                </div>
                                :
                                <>
                                    {
                                        siteData.documents.map((e) =>
                                            <li className="list-group-item">
                                                {e.name}
                                            </li>
                                        )
                                    }
                                </>
                        }
                    </ul>
                </div>

            </div>
        </>
    )
}