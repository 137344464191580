import React, { useContext, useEffect, useState } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

function EditFacilities(props) {
    // eslint-disable-next-line no-unused-vars
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [availableFacilities, setAvailableFacilities] = useState([]);
    const [myFacilities, setMyFacilities] = useState([]);

    const state = useContext(GlobalState);
    const [facilitiesData] = state.facilitiesApi.facilitiesData;
    const [siteData] = state.siteData;
    const [callback, setCallback] = state.callback;
    const [, setAlert] = state.alert;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        setAvailableFacilities([...facilitiesData].sort((a, b) => a.name.localeCompare(b.name)));
        setMyFacilities([...siteData.facilities].sort((a, b) => a.name.localeCompare(b.name)));
    }, [facilitiesData, siteData, callback]);

    const handleCheckboxChange = async (facilityId, isMyFacility) => {
        const selectedFacility = availableFacilities.find(facility => facility._id === facilityId);

        if (isMyFacility) {
            // Remove facility from "Your Facilities" list
            const updatedFacilities = myFacilities.filter(facility => facility._id !== facilityId);
            setMyFacilities(updatedFacilities);

            // Make API call to remove facility
            await removeFacility(facilityId);
        } else {
            // Remove facility from "Site Facilities" list
            const updatedFacilities = availableFacilities.filter(facility => facility._id !== facilityId);
            setAvailableFacilities(updatedFacilities);

            // Add facility to "Your Facilities" list
            setMyFacilities([...myFacilities, selectedFacility]);

            // Make API call to add facility
            await addFacility(facilityId);
        }
    };

    const addFacility = async (facilityId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/add-facilities`, { facilityId });

            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: "Failed to add facility",
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    };

    const removeFacility = async (facilityId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/remove-facilities`, { facilityId });

            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback); // Trigger callback to reset "Your Facilities" list
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: "Failed to remove facility",
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    };

    // Get the facilities that are not in "Your Facilities"
    const filteredAvailableFacilities = availableFacilities.filter(facility => !myFacilities.some(myFacility => myFacility._id === facility._id));

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Facilities</h5>
                </div>

                <div className='row mb-3'>
                    {/* Site Facilities */}
                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Please select from this list all the facilities that your site has</h6>
                                <p> <i>*To add a facility from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {filteredAvailableFacilities.map((facility) => (
                                    <div className='col-lg-6 py-2' key={facility._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedFacilities.includes(facility)}
                                                onChange={() => handleCheckboxChange(facility._id, false)}
                                                id={facility._id}
                                            />
                                            <label className="form-check-label" htmlFor={facility._id}>
                                                {facility.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Your Facilities */}
                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Your site's facilities</h6>
                                <p> <i>*To remove a facility from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {myFacilities.map((facility) => (
                                    <div className='col-lg-6 py-2' key={facility._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedFacilities.includes(facility)}
                                                onChange={() => handleCheckboxChange(facility._id, true)}
                                                id={facility._id}
                                            />
                                            <label className="form-check-label" htmlFor={facility._id}>
                                                {facility.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditFacilities;
