import React, { useState } from "react";
import { Link } from 'react-router-dom'

import { AiOutlineArrowRight } from 'react-icons/ai'

export default function StudyItem({ study }) {
    // const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4" >
                <div className="row study-item bg-white" style={{ boxShadow: '2px 3px 2px 2px #f1f1f1' }}>
                    <div className="col-lg-9 p-3" style={{ borderRight: '1px solid #333' }}>
                        <div className="d-flex justify-content-between flex-wrap flex-sm-wrap top mb-3">
                            <button className="btn-b"> unknown </button>

                            <div className="n"> {study && study.study_id ? `${study.study_id}` : "unknown"} </div>
                        </div>

                        <div className="">
                            <h5>
                                {study && study.study_title ? study.study_title : "Title not available"}
                                {/* Title */}
                            </h5>

                            <p>
                                {study && study.study_description.length < 200
                                    ? study.study_description
                                    : "Description not available"}

                                {study && study.study_description.length > 200
                                    ? study.study_description.slice(0, 200) + ' .......'
                                    : "Description not available"}
                            </p>

                            <h6 className="text-danger">Submission Date: {study && study.deadline_for_submission ? study.deadline_for_submission : "Unknown"}</h6>
                        </div>
                    </div>

                    <div className="col-lg-3 p-3">
                        <li>
                            <Link to={`/discover-studies/${study && study._id ? study._id : ''}`}>
                                <button className="btn-b">
                                    See Details
                                    <span> <AiOutlineArrowRight /></span>
                                </button>
                            </Link>
                        </li>
                    </div>
                </div>
            </div>
        </>
    )
}
