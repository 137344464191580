import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function SiteFacilities() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    const [siteFacilities, setSiteFacilities] = useState([]);

    useEffect(() => {
        setSiteFacilities([...siteData.facilities])
    }, [siteData])

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Facilities</h5>

                    <Link to="/site/facilities/edit-facilities"><button className="btn-d">Edit</button></Link>
                </div>

                <div className="bg-white p-3">
                    <div className="row">

                        {
                            siteFacilities.length === 0 ?
                                <>
                                    <h6>You haven't added any site facility yet!!!</h6>
                                </>
                                : <>
                                    {
                                        siteFacilities.map((facility) =>
                                            <div className="col-lg-3 si-sec-b" key={facility._id}>
                                                <li> {facility.name} </li>
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}