import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function SiteInfoHeader () {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Information</h5>

                    <Link to="/site/edit-site"><button className="btn-d">Edit</button></Link>
                </div>

                <div className="bg-white p-3 mb-3">
                    <div>
                        <div className="row">
                            <div className="col-lg-6 si-sec-b">
                                <h6>Site Name</h6>
                                <p> {siteData.site_name} </p>
                            </div>
                            <div className="col-lg-6 si-sec-b">
                                <h6>Study Phase</h6>
                                <p>
                                    {siteData.is_phase_one_site === true ? "Phase 1, ": ""} {siteData.is_phase_two_site === true ? "Phase 2, ": ""} {siteData.is_phase_three_site === true ? "Phase 3, ": ""} {siteData.is_phase_four_site === true ? "Phase 4 ": ""}
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 si-sec-b">
                                <h6>Type of Site</h6>
                                <p> {siteData.type_of_site} </p>
                            </div>
                            <div className="col-lg-6 si-sec-b">
                                <h6>Year Established</h6>
                                <p> {siteData.year_established} </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 si-sec-b">
                                <h6>Primary Phone</h6>
                                <p> {siteData.site_phone_number} </p>
                            </div>
                            <div className="col-lg-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-3 mb-3">
                    <h6>Contact Information</h6>
                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>Email</h6>
                            <p> {siteData.email ? `${siteData.email}` : "nil"} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Phone Number</h6>
                            <p> {siteData.site_phone_number} </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>Street Address</h6>
                            <p> {siteData.street_address} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Apt, Suite, etc</h6>
                            <p> {siteData.apt_suite_ect} </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>City</h6>
                            <p> {siteData.city} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>State/Province</h6>
                            <p> {siteData.state_province} </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>Country/Region</h6>
                            <p> {siteData.country} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Zip/Post code</h6>
                            <p> {siteData.zip_postcode} </p>
                        </div>
                    </div>
                </div>

                <div className="bg-white p-3">
                    <h6>Additional Information</h6>
                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>Do you work with other TMOs, SMOs or Contract Orgs?</h6>
                            <p> {siteData.email ? `${siteData.email}` : "nil"} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Do you have written SOPs?</h6>
                            <p> {siteData.site_phone_number} </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>Does your site have dedicated patient recruitment personnel?</h6>
                            <p> {siteData.email ? `${siteData.email}` : "nil"} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Does your site have dedicated data entry staff?</h6>
                            <p> {siteData.site_phone_number} </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 si-sec-b">
                            <h6>How many coordinator(s) does your site have?</h6>
                            <p> {siteData.email ? `${siteData.email}` : "nil"} </p>
                        </div>
                        <div className="col-lg-6 si-sec-b">
                            <h6>Does your site have dedicated regulatory personnel?</h6>
                            <p> {siteData.site_phone_number} </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}