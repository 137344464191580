import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function SecondaryContact() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    const [secContact, setSecContact] = useState(null);

    useEffect(() => {
        setSecContact(siteData.secondaryContact);
    }, [siteData]);

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Secondary Site Contact</h5>
                    <Link to="/site/secondary-contact/edit-contact">
                        <button className="btn-d"> Edit </button>
                    </Link>
                </div>

                <div className="bg-white p-3">
                    {secContact ? (
                        <>
                            <div className="si-sec-b">
                                <h6>Fullname</h6>
                                <p>{secContact.fullname}</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 si-sec-b">
                                    <h6>Title</h6>
                                    <p>{secContact.title}</p>
                                </div>
                                <div className="col-lg-6 si-sec-b">
                                    <h6>Email</h6>
                                    <p>{secContact.email}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 si-sec-b">
                                    <h6>Primary Phone Number</h6>
                                    <p>{secContact.primary_phone_number}</p>
                                </div>
                                <div className="col-lg-6 si-sec-b">
                                    <h6>Secondary Phone Number</h6>
                                    <p>{secContact.secondary_phone_number}</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>Secondary contact data is not available.</p>
                    )}
                </div>
            </div>
        </>
    );
}
