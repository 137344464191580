import React, { useContext, useState } from "react";
import CSidebar from "../../components/Nav/CSidebar";
import Header from "../../components/Header/Header";
import { GlobalState } from "../../GlobalState";
import Nodata from "../../utils/Nodata";
import axios from "axios";

const initialState = {
    fullname: "",
    email: "",
    title: "",
    primary_phone_number: "",
    cell_phone_number: "",
    site: ""
}

export default function SiteStaffs() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;
    const [siteStaffsData] = state.siteStaffsApi.siteStaffsData;
    const [callback, setCallback] = state.siteStaffsApi.callback;
    const [, setAlert] = state.alert;

    const [details, setDetails] = useState(initialState);
    const { fullname, email, title, primary_phone_number, cell_phone_number } = details;

    const handleChange = e => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const submitData = async (e) => {
        e.preventDefault();

        if (!fullname || !email || !title || !primary_phone_number) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'one or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(`${API_ENDPOINT}/site-staffs`, { ...details, site: siteData._id })
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setCallback(!callback)
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }
    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            {/* {
                                studiesData.length === 0 ?
                                    <>
                                        <Nodata />
                                    </>
                                    : <>
                                        <div style={{ margin: "0 auto", maxWidth: "1320px" }}>
                                            <h6>Hello, Guest!</h6>
                                            <p>Here are a list of studies, we have for you today.</p>
                                        </div>

                                       
                                    </>
                            } */}

                            <div className="bg-white p-3">
                                <h6 className="mb-4">Staff Members Management</h6>

                                <div className="d-flex justify-content-between mb-4">
                                    <p>Invite members of your staff to have access to your site portal</p>

                                    <button type="button" class="btn-b" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                        Invite Member
                                        <span>
                                            +
                                        </span>
                                    </button>
                                </div>

                                <div className="">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h6>List of Staff Members</h6>


                                            <div className="bg-light p-3">
                                                {
                                                    siteStaffsData.length === 0 ?
                                                        <>
                                                            <Nodata />
                                                        </>
                                                        :
                                                        <>
                                                            <ul >
                                                                {
                                                                    siteStaffsData.map(e =>
                                                                        <li className="d-flex justify-content-between flex-wrap p-2 mb-3 border">
                                                                            <div className="d-flex me-3">
                                                                                <div className="iv-staff-bg">
                                                                                    <h6>NN</h6>
                                                                                </div>
                                                                                <div>
                                                                                    <h6> {e.fullname} </h6>
                                                                                    <p>
                                                                                        {e.email}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <button className="btn btn-sm btn-primary">Grant Access</button>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- Modal --> */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <form onSubmit={submitData}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Add a new staff</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="fullname" className="form-label">Fullname</label>
                                    <input type="text" className="form-control form-control-md" name="fullname" id="fullname"
                                        value={fullname} onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control form-control-md" name="email" id="email"
                                        value={email} onChange={handleChange}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control form-control-md" name="title" id="title"
                                        value={title} onChange={handleChange}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="primary_phone_number" className="form-label">Primary phone number</label>
                                        <input type="text" className="form-control form-control-md" name="primary_phone_number" id="primary_phone_number"
                                            value={primary_phone_number} onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="cell_phone_number" className="form-label">Cell phone number (optional) </label>
                                        <input type="text" className="form-control form-control-md" name="cell_phone_number" id="cell_phone_number"
                                            value={cell_phone_number} onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn-d" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn-a">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}