import React, { useContext, useState, useEffect } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

function EditTherapeuticAreas(props) {
    // eslint-disable-next-line no-unused-vars
    const [selectedTherapeuticAreas, setSelectedTherapeuticAreas] = useState([]);
    const [availableTherapeuticAreas, setAvailableTherapeuticAreas] = useState([]);
    const [myTherapeuticAreas, setMyTherapeuticAreas] = useState([]);

    const state = useContext(GlobalState);
    const [therapeuticAreasData] = state.therapeuticAreasApi.therapeuticAreasData;
    const [siteData] = state.siteData;
    const [callback, setCallback] = state.callback;
    const [, setAlert] = state.alert;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        setAvailableTherapeuticAreas([...therapeuticAreasData].sort((a, b) => a.name.localeCompare(b.name)));
        setMyTherapeuticAreas([...siteData.therapeuticAreas].sort((a, b) => a.name.localeCompare(b.name)));
    }, [therapeuticAreasData, siteData]);


    const handleCheckboxChange = async (therapeuticAreaId, isMyFacility) => {
        const selectedCapability = availableTherapeuticAreas.find((therapeuticArea) => therapeuticArea._id === therapeuticAreaId);

        if (isMyFacility) {
            const updatedFacilities = myTherapeuticAreas.filter((therapeuticArea) => therapeuticArea._id !== therapeuticAreaId);
            setMyTherapeuticAreas(updatedFacilities);

            try {
                await removetherapeuticArea(therapeuticAreaId);
            } catch (error) {
                handleApiError(error, 'Failed to remove therapeuticArea.');
            }
        } else {
            const updatedFacilities = availableTherapeuticAreas.filter((therapeuticArea) => therapeuticArea._id !== therapeuticAreaId);
            setAvailableTherapeuticAreas(updatedFacilities);

            setMyTherapeuticAreas([...myTherapeuticAreas, selectedCapability]);

            try {
                await addtherapeuticArea(therapeuticAreaId);
            } catch (error) {
                handleApiError(error, 'Failed to add therapeuticArea.');
            }
        }
    };

    const handleApiError = (error, message) => {
        console.error('API Error:', error);
        setAlert({
            failure: true,
            success: false,
            failureMsg: message || "An unexpected error occurred",
            successMsg: '',
        });
        setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
    };

    const addtherapeuticArea = async (therapeuticAreaId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/add-therapeutic-areas`, { therapeuticAreaId });
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                handleApiError(null, 'Failed to add therapeuticArea. Unexpected status code: ' + response.status);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const removetherapeuticArea = async (therapeuticAreaId) => {
        try {
            const response = await axios.put(`${API_ENDPOINT}/sites/${siteData._id}/remove-therapeutic-areas`, { therapeuticAreaId });
            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
                setCallback(!callback);
            } else {
                handleApiError(null, 'Failed to remove therapeuticArea. Unexpected status code: ' + response.status);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const filteredAvailableCapabilities = availableTherapeuticAreas.filter(
        (therapeuticArea) => !myTherapeuticAreas.some((myCapability) => myCapability._id === therapeuticArea._id)
    );

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Site Therapeutic Areas</h5>
                </div>

                <div className='row mb-3'>
                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Please select from this list all the therapeutic areas that your site has</h6>
                                <p> <i>*To add a therapeutic area from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {filteredAvailableCapabilities.map((therapeuticArea) => (
                                    <div className='col-lg-6 py-2' key={therapeuticArea._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedTherapeuticAreas.includes(therapeuticArea)}
                                                onChange={() => handleCheckboxChange(therapeuticArea._id, false)}
                                                id={therapeuticArea._id}
                                            />
                                            <label className="form-check-label" htmlFor={therapeuticArea._id}>
                                                {therapeuticArea.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 p-3'>
                        <div className='border p-2'>
                            <div className='mb-3'>
                                <h6>Your site's therapeutic area</h6>
                                <p> <i>*To remove a therapeutic area from your list, kindly mark the checkbox</i> </p>
                            </div>

                            <div className='row'>
                                {myTherapeuticAreas.map((therapeuticArea) => (
                                    <div className='col-lg-6 py-2' key={therapeuticArea._id}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedTherapeuticAreas.includes(therapeuticArea)}
                                                onChange={() => handleCheckboxChange(therapeuticArea._id, true)}
                                                id={therapeuticArea._id}
                                            />
                                            <label className="form-check-label" htmlFor={therapeuticArea._id}>
                                                {therapeuticArea.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditTherapeuticAreas;