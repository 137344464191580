import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios'
import { GlobalState } from '../../GlobalState'

import Logo from '../../assets/images/logo.jpg';
import { PiTrademarkRegisteredLight } from 'react-icons/pi'

const initialState = {
    fullname: "",
    email: "",
    password: "",
    cf_password: ""
}

export default function Register() {
    const [userDetails, setUserDetails] = useState(initialState);
    const { fullname, email, password, cf_password } = userDetails;

    const [typePass, setTypePass] = useState(false)
    const [typeCfPass, setTypeCfPass] = useState(false)
    const [passError, setPassError] = useState(false)

    const state = useContext(GlobalState);
    const [, setAlert] = state.alert;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const createAccount = async (e) => {
        e.preventDefault()

        if (!fullname || !email || !password || !cf_password) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        if (password !== cf_password) {
            setPassError(!passError)
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'Passwords do not match',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }
        try {
            const res = await axios.post(`${API_ENDPOINT}/register`, { ...userDetails })
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setUserDetails(initialState)
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }


    return (
        <>
            <div className="auth-page">
                <div className="auth-form">
                    <div className="bg-white py-4 px-3">
                        <div className="text-center py-2">
                            <img src={Logo} alt="logo" className="auth-logo" />
                            <h6 className="auth-cap-txt pt-2" style={{ fontSize: '18px' }}>Discover new studies, Sign up</h6>
                            <p className="auth-cap-txt" style={{ fontSize: '10px' }}>By signing up, you agree to our terms of service</p>
                        </div>

                        <form onSubmit={createAccount}>
                            <div className="mb-3">
                                <label htmlFor="fullname" className="form-label text-uppercase fw-bold auth-label">Full Name</label>
                                <input type="text" id="fullname" name="fullname" className="form-control form-control-sm"
                                    value={fullname} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label text-uppercase fw-bold auth-label">Email Address</label>
                                <input type="email" id="email" name="email" className="form-control form-control-sm"
                                    value={email} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="password" className="form-label text-uppercase fw-bold auth-label">Password</label>
                                    <input type={typePass ? "text" : "password"} id="password" name="password" className="form-control form-control-sm"
                                        value={password} onChange={handleChange}
                                    />

                                    <small onClick={() => setTypePass(!typePass)} >
                                        {typePass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="pass">
                                    <label htmlFor="cf_password" className="form-label text-uppercase fw-bold auth-label">Confirm Password</label>
                                    <input type={typeCfPass ? "text" : "password"} id="cf_password" name="cf_password" className="form-control form-control-sm"
                                        value={cf_password} onChange={handleChange} />

                                    <small onClick={() => setTypeCfPass(!typeCfPass)} >
                                        {typeCfPass ? 'Hide' : 'Show'}
                                    </small>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center pt-4 pb-3">
                                <button className="btn-a" type="submit">
                                    Create Account
                                    <span className="ms-2"><PiTrademarkRegisteredLight /></span>
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="bg-light pt-3 pb-2">
                        <p className="text-center auth-oth-link">Don't have an account? <Link to="/login">Login</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}