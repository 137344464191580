import React, { useContext, useState } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

const initialState = {
    firstname: "",
    lastname: "",
    credentials: "",
    national_provider_identification: "",
    specialty: "",
    status: "",
    board_certified: false,
    board_certified_field: "",
    specialties: [],
    phonenumber: "",
    phonenumber_two: "",
    email: "",
    investigator_medical_license_exp_date: "",
    investigator_cv_signed_date: "",
    investigator_dea_license_exp_time: "",
    additional_info: "",
    site: ""
}

function InvestigatorsForm(props) {
    const [investigator, setInvestigator] = useState(initialState)
    const { firstname, lastname, credentials, national_provider_identification, status, board_certified, board_certified_field, specialties, phonenumber, phonenumber_two,
        email, investigator_medical_license_exp_date, investigator_cv_signed_date, investigator_dea_license_exp_time, additional_info } = investigator;

    const state = useContext(GlobalState)
    const [siteData] = state.siteData
    const [, setAlert] = state.alert;


    const [investigator_medical_license, setInvestigator_medical_license] = useState("")
    const [investigator_cv, setInvestigator_cv] = useState("")
    const [investigator_dea_license, setInvestigator_dea_license] = useState("")

    const handleChange = e => {
        const { name, value } = e.target;

        // Special handling for board_certified field
        if (name === 'board_certified') {
            const isBoardCertified = value === 'true';
            setInvestigator({
                ...investigator,
                [name]: isBoardCertified,
                board_certified_field: isBoardCertified ? board_certified_field : '', // Clear the field if "No" is selected
            });
        } else {
            setInvestigator({ ...investigator, [name]: value });
        }
    }

    const handleSpecialtyChange = (e) => {
        const selectedSpecialty = e.target.value;

        // Check if the selected specialty is already in the array
        if (specialties.includes(selectedSpecialty)) {
            // If yes, remove it
            const updatedSpecialties = specialties.filter(spec => spec !== selectedSpecialty);
            setInvestigator({ ...investigator, specialties: updatedSpecialties });
        } else {
            // If no, add it to the array
            setInvestigator({ ...investigator, specialties: [...specialties, selectedSpecialty] });
        }
    }

    const handleMedicalLicenseChange = (e) => {
        const file = e.target.files[0];
        setInvestigator_medical_license(file);
    };

    const handleCvChange = (e) => {
        const file = e.target.files[0];
        setInvestigator_cv(file);
    };

    const handleDeaLicenseChange = (e) => {
        const file = e.target.files[0];
        setInvestigator_dea_license(file);
    };


    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            // Form validation
            if (!firstname || !lastname || !credentials || !board_certified || !specialties || !phonenumber ||
                !email || !investigator_medical_license || !investigator_medical_license_exp_date
                || !investigator_cv || !investigator_cv_signed_date) {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: 'One or more fields are empty',
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
                return;
            }

            const medicalLicenseFormData = new FormData();
            medicalLicenseFormData.append("file", investigator_medical_license);

            const cvFormData = new FormData();
            cvFormData.append("file", investigator_cv);

            const deaLicenseFormData = new FormData();
            deaLicenseFormData.append("file", investigator_dea_license);

            // Upload files
            const medicalLicenseResponse = await axios.post(
                `${API_ENDPOINT}/investigator-docs`,
                medicalLicenseFormData
            );

            const cvResponse = await axios.post(
                `${API_ENDPOINT}/investigator-docs`,
                cvFormData
            );

            const deaLicenseResponse = await axios.post(
                `${API_ENDPOINT}/investigator-docs`,
                deaLicenseFormData
            );

            // Include file URLs in the data to be sent
            const response = await axios.post(
                `${API_ENDPOINT}/investigators`,
                {
                    ...investigator,
                    site: siteData.site._id,
                    investigator_medical_license: medicalLicenseResponse.data.url,
                    investigator_cv: cvResponse.data.url,
                    investigator_dea_license: deaLicenseResponse.data.url,
                }
            );

            if (response.status === 200) {
                setAlert({
                    failure: false,
                    success: true,
                    failureMsg: response.data.msg,
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            } else {
                setAlert({
                    failure: true,
                    success: false,
                    failureMsg: "Failed to add investigator",
                    successMsg: '',
                });
                setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }


    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Add Investigator</h5>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='bg-white p-3 mb-3'>
                        <h6>Principal Investigators Details</h6>

                        <div className='row'>
                            <div className='mb-3'>
                                <label htmlFor='firstname' className='form-label label-i'>First name</label>
                                <input type='text' className='form-control form-control-md' id='firstname' name='firstname'
                                    value={firstname} onChange={handleChange}
                                />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor='lastname' className='form-label label-i'>Last name</label>
                                <input type='text' className='form-control form-control-md' id='lastname' name='lastname'
                                    value={lastname} onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='credentials' className='form-label label-i'>Credentials</label>
                                <select type='text' className='form-select form-control-md' id='credentials' name='credentials'
                                    value={credentials} onChange={handleChange}
                                >
                                    <option>...</option>
                                    <option value="MD"> MD </option>
                                    <option value="DO">DO</option>
                                    <option value="Ph.D">Ph.D</option>
                                    <option value="NP">NP</option>
                                    <option value="DPM">DPM</option>
                                </select>
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='national_provider_identification' className='form-label label-i'>National Provider Identification (optional)</label>
                                <input type='text' className='form-control form-control-md' id='national_provider_identification' name='national_provider_identification'
                                    value={national_provider_identification} onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='row my-4'>
                            <div className='col-lg-6 row'>
                                <div className='col-lg-6'>
                                    <label htmlFor='status' className='form-label label-i'>Board Certified</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="board_certified"
                                                id="inlineRadio1"
                                                value="true"
                                                checked={board_certified === true}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="board_certified"
                                                id="inlineRadio2"
                                                value="false"
                                                checked={board_certified === false}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <label className='form-label label-i' htmlFor='board_certified_field'>IF Yes, In what...</label>
                                    <input
                                        type='text'
                                        className={`form-control form-control-md ${board_certified ? '' : 'd-none'}`}
                                        id='board_certified_field'
                                        name='board_certified_field'
                                        value={board_certified_field}
                                        onChange={handleChange}
                                        disabled={!board_certified}
                                    />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <label htmlFor='status' className='form-label label-i'>Status</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="ownerRadio"
                                            value="owner"
                                            checked={status === 'owner'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="ownerRadio">Owner</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id="subcontractorRadio"
                                            value="subcontractor"
                                            checked={status === 'subcontractor'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="subcontractorRadio">Subcontractor</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <label className='form-label mb-2 mt-3 label-i' htmlFor='specialties'>Specialty/Specialties</label>

                            <div className='row'>
                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Obstetrics and Gynecology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Obstetrics and Gynecology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Obstetrics and Gynecology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Family Medicine" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Family Medicine")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Family Medicine
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Pulmonology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Pulmonology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Pulmonology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Podiatry" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Podiatry")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Podiatry
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Psychiatry" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Psychiatry")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Psychiatry
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Internal Medicine" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Internal Medicine")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Internal Medicine
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Pediatrics" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Pediatrics")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Pediatrics
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Dermatology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Dermatology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Dermatology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Gastroenterology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Gastroenterology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Gastroenterology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Anesthesiology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Anesthesiology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Anesthesiology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Emergency Medicine" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Emergency Medicine")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Emergency Medicine
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Rheumatology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Rheumatology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Rheumatology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Orthopedic Surgery" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Orthopedic Surgery")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Orthopedic Surgery
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Neurology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Neurology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Neurology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Physical Medicine and Rehabilitation" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Physical Medicine and Rehabilitation")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Physical Medicine and Rehabilitation
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Surgery" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Surgery")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Surgery
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Urology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Urology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Urology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Nephrology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Nephrology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Nephrology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Psychology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Psychology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Psychology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Otolaryngology (ENT)" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Otolaryngology (ENT)")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Otolaryngology (ENT)
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Cardiology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Cardiology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Cardiology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Allergy and Immunology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Allergy and Immunology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Allergy and Immunology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Infectious Diseases" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Infectious Diseases")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Infectious Diseases
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Open" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Open")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Open
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Radiology" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Radiology")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Radiology
                                    </label>
                                </div>

                                <div className="col-lg-4 py-2 form-check">
                                    <input className="form-check-input" type="checkbox" value="Other" id="flexCheckDefault"
                                        onChange={handleSpecialtyChange}
                                        checked={specialties.includes("Other")}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Other
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='bg-white p-3 mb-3'>
                        <h6>Contact Information</h6>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='phonenumber' className='form-label label-i'>Phone number #1</label>
                                <input type='text' className='form-control form-control-md' id='phonenumber' name='phonenumber'
                                    value={phonenumber} onChange={handleChange}
                                />
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='phonenumber_two' className='form-label label-i'>Phone Number #2 (optional)</label>
                                <input type='text' className='form-control form-control-md' id='phonenumber_two' name='phonenumber_two'
                                    value={phonenumber_two} onChange={handleChange}
                                />
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='email' className='form-label label-i'>Email</label>
                                <input type='text' className='form-control form-control-md' id='email' name='email'
                                    value={email} onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='bg-white p-3 mb-3'>
                        <h6>Licenses & Documents</h6>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_medical_license' className='form-label label-i'>Investigator Medical License</label>
                                <input
                                    type="file"
                                    className="form-control form-control-md"
                                    id="investigator_medical_license"
                                    name="investigator_medical_license"
                                    onChange={handleMedicalLicenseChange}
                                />
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_medical_license_exp_date' className='form-label label-i'>License Expiration Date</label>
                                <input type='date' className='form-control form-control-md' id='investigator_medical_license_exp_date' name='investigator_medical_license_exp_date'
                                    value={investigator_medical_license_exp_date} onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_cv' className='form-label label-i '  >Investigator CV</label>
                                <input
                                    type="file"
                                    className="form-control form-control-md"
                                    id="investigator_cv"
                                    name="investigator_cv"
                                    onChange={handleCvChange}
                                />
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_cv_signed_date' className='form-label label-i'>CV Signed Date</label>
                                <input type='date' className='form-control form-control-md' id='investigator_cv_signed_date' name='investigator_cv_signed_date'
                                    value={investigator_cv_signed_date} onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_dea_license' className='form-label label-i'>Investigator DEA License (optional)</label>
                                <input
                                    type="file"
                                    className="form-control form-control-md"
                                    id="investigator_dea_license"
                                    name="investigator_dea_license"
                                    onChange={handleDeaLicenseChange}
                                />
                            </div>

                            <div className='col-lg-6 mb-3'>
                                <label htmlFor='investigator_dea_license_exp_time' className='form-label label-i'>DEA License Expiration date (optional)</label>
                                <input type='date' className='form-control form-control-md' id='investigator_dea_license_exp_time' name='investigator_dea_license_exp_time'
                                    value={investigator_dea_license_exp_time} onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='bg-white p-3 mb-3'>
                        <h6>Additional Information</h6>

                        <textarea className='form-control' id='additional_info' name='additional_info' value={additional_info} onChange={handleChange}></textarea>
                    </div>

                    <div className='d-flex mb-3'>
                        <button className='btn-d me-2'>Cancel</button>
                        <button className='btn-a' type='submit'>Save</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default InvestigatorsForm;