import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalState } from "../../GlobalState";

export default function PrincipalInvestigators() {
    const state = useContext(GlobalState);
    const [siteData] = state.siteData;

    const [investigators, setInvestigators] = useState([]);

    useEffect(() => {
        setInvestigators([...siteData.investigators])
    }, [siteData])

    return (
        <>
            <div className="p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h5>Principal Investigators</h5>

                    <Link to="/site/investigators/add-investigator"><button className="btn-d">Add</button></Link>
                </div>

                <div>
                    {
                        investigators.length === 0 ?
                            <>
                                <div className="bg-white p-5 mb-3">
                                    <h6>No data available yet, please add your site's investigators</h6>
                                </div>
                            </>
                            : <>
                                {
                                    investigators.map((e, i) =>
                                        <div className="bg-white p-3 mb-3">
                                            <div className="row">
                                                <div className="col-lg-3 pi-sec-b">
                                                    <p>PI #{i + 1} </p>
                                                    <h6 style={{ color: "#333", fontWeight: '500' }}> {e.firstname} {e.lastname} </h6>
                                                </div>

                                                <div className="col-lg-3 pi-sec-b">
                                                    <p>Credentials</p>
                                                    <h6 style={{ color: "#333", fontWeight: '500' }}> {e.credentials} </h6>
                                                </div>

                                                <div className="col-lg-3 pi-sec-b">
                                                    <p>Specialty/Specialities</p>
                                                    <h6 style={{ color: "#333", fontWeight: '500' }}> {e.specialties} </h6>
                                                </div>

                                                <div className="col-lg-3 pi-sec-b">
                                                    <p>Board Certified</p>
                                                    <h6 style={{ color: "#333", fontWeight: '500' }}> {e.board_certified} </h6>
                                                </div>
                                            </div>
                                            <div className="border-top pt-3 d-flex">
                                                <button className="btn-b me-2">Edit</button>
                                                <button className="btn-d">Delete</button>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                    }
                </div>

            </div>
        </>
    )
}