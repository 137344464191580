import React, { useContext, useState } from 'react';
import { GlobalState } from '../../../GlobalState';
import axios from 'axios';

const initialState = {
    fullname: "",
    email: "",
    title: "",
    primary_phone_number: "",
    secondary_phone_number: "",
    cda_authorised_signatory: false,
    cta_authorised_signatory: false,
    budget_contract_authorised: false,
    responsible_for_regulatory_documents: false
}

function SecContactForm(props) {
    const [secContactDetails, setSecContactDetails] = useState(initialState)
    const { fullname, email, title, primary_phone_number, secondary_phone_number, cda_authorised_signatory,
        cta_authorised_signatory, budget_contract_authorised, responsible_for_regulatory_documents } = secContactDetails;

    const state = useContext(GlobalState);
    const [siteData] = state.siteData;
    const [, setAlert] = state.alert;

    const handleChange = e => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setSecContactDetails({ ...secContactDetails, [name]: checked });
        } else {
            setSecContactDetails({ ...secContactDetails, [name]: value });
        }
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const submitForm = async (e) => {
        e.preventDefault()

        if (!fullname || !email || !title || !primary_phone_number) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(
                `${API_ENDPOINT}/secondary-contact`,
                {
                    ...secContactDetails, site: siteData._id,
                    cda_authorised_signatory: Boolean(secContactDetails.cda_authorised_signatory),
                    cta_authorised_signatory: Boolean(secContactDetails.cta_authorised_signatory),
                    budget_contract_authorised: Boolean(secContactDetails.budget_contract_authorised),
                    responsible_for_regulatory_documents: Boolean(secContactDetails.responsible_for_regulatory_documents),
                },
                { withCredentials: true, credentials: 'include' }
            )
            // console.log(res)
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            setSecContactDetails(initialState);
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <>
            <div className="bg-white p-3 border rounded-2">
                <div className="d-flex justify-content-between mb-3">
                    <h6 className='text-capitalize'>Add Secondary contact information</h6>
                </div>

                <form onSubmit={submitForm}>
                    <div className='row'>
                        <div className='col-lg-12 mb-3'>
                            <label htmlFor='fullname' className='form-label'>Fullname</label>
                            <input type="text" className='form-control form-control-md' name='fullname' id='fullname'
                                value={fullname} onChange={handleChange}
                            />
                        </div>

                        <div className='col-lg-6 mb-3'>
                            <label htmlFor='title' className='form-label'>Title</label>
                            <input type="text" className='form-control form-control-md' name='title' id='title'
                                value={title} onChange={handleChange}
                            />
                        </div>

                        <div className='col-lg-6 mb-3'>
                            <label htmlFor='email' className='form-label'>Email</label>
                            <input type="text" className='form-control form-control-md' name='email' id='email'
                                value={email} onChange={handleChange}
                            />
                        </div>

                        <div className='col-lg-6 mb-3'>
                            <label htmlFor='primary_phone_number' className='form-label'>Primary phone number</label>
                            <input type="text" className='form-control form-control-md' name='primary_phone_number' id='primary_phone_number'
                                value={primary_phone_number} onChange={handleChange}
                            />
                        </div>

                        <div className='col-lg-6 mb-3'>
                            <label htmlFor='secondary_phone_number' className='form-label'>Secondary phone number</label>
                            <input type="text" className='form-control form-control-md' name='secondary_phone_number' id='secondary_phone_number'
                                value={secondary_phone_number} onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className=''>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={cda_authorised_signatory} id="cda_authorised_signatory"
                                name='cda_authorised_signatory' onChange={handleChange}
                            />
                            <label className="form-check-label" for="flexCheckDefault">
                                Authorized Signatory for CDA (Confidential Disclosure Agreement)
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={cta_authorised_signatory} id="cta_authorised_signatory"
                                name='cta_authorised_signatory' onChange={handleChange}
                            />
                            <label className="form-check-label" for="flexCheckChecked">
                                Authorized Signatory for CTA (Clinical Trial Agreement)
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={budget_contract_authorised} id="budget_contract_authorised"
                                name='budget_contract_authorised' onChange={handleChange}
                            />
                            <label className="form-check-label" for="flexCheckChecked">
                                Authorized to negotiate contracts and budget
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={responsible_for_regulatory_documents} id="responsible_for_regulatory_documents"
                                name='responsible_for_regulatory_documents' onChange={handleChange}
                            />
                            <label className="form-check-label" for="flexCheckChecked">
                                Responsible for completing regulatory documents
                            </label>
                        </div>
                    </div>

                    <div className='py-2'>
                        <button className='btn-d me-2'>Cancel</button>
                        <button className='btn-b' type='submit'>Save</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default SecContactForm;