import { useState, useEffect } from 'react';
import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function StudiesAPI(clientId) {
    const [loading, setLoading] = useState(false);
    const [studiesData, setStudiesData] = useState([]);
    const [error, setError] = useState(null);
    const [callback, setCallback] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [result, setResult] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (clientId) {
            const getData = async () => {
                try {
                    setLoading(true);
                    const res = await axios.get(`${API_ENDPOINT}/studies/available/${clientId}`);
                    // console.log(res);
                    setStudiesData(res.data.items);
                    setResult(res.data.result);
                    setTotal(res.data.total);
                } catch (error) {
                    if (axios.isCancel(error)) {
                        // Request canceled, no need to handle it as an error
                        console.log('Request canceled:', error.message);
                    } else {
                        // Handle other types of errors
                        console.error('Error fetching studies:', error);

                        if (error.response && error.response.data.msg === "Please complete all required site details") {
                            // Display specific error message to the user
                            setError("Please complete all required site details");
                        } else {
                            // Display generic error message
                            setError('An unexpected error occurred. Please try again.');
                        }
                    }
                } finally {
                    setLoading(false);
                }
            };
            getData();
        }

        // Cleanup function to cancel the request when the component unmounts
        return () => {
            source.cancel('Request canceled by cleanup');
        };
    }, [callback, clientId]);

    return {
        loading: [loading, setLoading],
        studiesData: [studiesData, setStudiesData],
        error: [error, setError],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        total: [total, setTotal]
    };
}
