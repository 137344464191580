import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import { GlobalState } from "../../GlobalState";

import Logo from '../../assets/images/logo.jpg'
import { AiOutlineLogin } from 'react-icons/ai'

const initialState = {
    email: ""
}

export default function ForgetPassword() {
    const [userDetails, setUserDetails] = useState(initialState);
    const { email } = userDetails;

    const state = useContext(GlobalState);
    const [, setAlert] = state.alert;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value })
    }

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

    const handleForgetPassword = async (e) => {
        e.preventDefault()

        if (!email) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: 'One or more fields are empty',
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
            return;
        }

        try {
            const res = await axios.post(
                `${API_ENDPOINT}/forget-password`,
                { ...userDetails },
                { withCredentials: true, credentials: 'include' }
            )
            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
            // console.log(res)
            // localStorage.setItem('firstLogin', true)
            window.location.href = "/";
            setUserDetails(initialState)
        } catch (error) {
            // console.log(error)
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    return (
        <>
            <div className="auth-page">
                <div className="auth-form">
                    <div className="bg-white py-4 px-3">
                        <div className="text-center py-2">
                            <img src={Logo} alt="logo" className="auth-logo" />
                            <h6 className="auth-cap-txt">Sign in to your account</h6>
                        </div>

                        <form onSubmit={handleForgetPassword}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label text-uppercase fw-bold auth-label">Email Address</label>
                                <input type="email" id="email" name="email" className="form-control form-control-sm"
                                    value={email} onChange={handleChange} />
                            </div>

                            <div className="d-flex justify-content-center pt-4 pb-3">
                                <button className="btn-a" type="submit">
                                    Send reset link
                                    <span className="ms-2"><AiOutlineLogin /></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="bg-light pt-3 pb-2">
                        <p className="text-center auth-oth-link">Back to <Link to="/login">Sign in page</Link></p>
                        <p className="text-center auth-oth-link">Back to <Link to="/">Home</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}