import React from 'react';

function Loader(props) {
    return (
        <div>
            <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
        </div>
    );
}

export default Loader;