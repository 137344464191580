import { useState, useEffect } from 'react';
import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


export default function SiteStaffsAPI(site_id) {
    const [loading, setLoading] = useState(false);
    const [siteStaffsData, setSiteStaffsData] = useState([])
    const [error, setError] = useState(null)
    const [callback, setCallback] = useState(false)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [result, setResult] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const source = axios.CancelToken.source();
        // console.log(site_id)
        if (site_id) {
            const getData = async () => {
                try {
                    setLoading(true);
                    const res = await axios.get(`${API_ENDPOINT}/site-staffs?site=${site_id}`)
                    // console.log(res)
                    setSiteStaffsData(res.data.items)
                    setResult(res.data.result)
                    setTotal(res.data.total)
                } catch (error) {
                    if (axios.isCancel(error)) {
                        // Request canceled, no need to handle it as an error
                        console.log('Request canceled:', error.message);
                    } else {
                        // Handle other types of errors
                        console.error('Error fetching studies:', error);

                        if (error.response) {
                            // The request was made, but the server responded with an error status
                            console.error('Server error response:', error.response.data);

                            // Display server error message to the user
                            setError(`Server error: ${error.response.data.message}`);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.error('No response from the server:', error.request);

                            // Display a generic error message to the user
                            setError('Failed to fetch studies. Please try again.');
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.error('Request setup error:', error.message);

                            // Display a generic error message to the user
                            setError('An unexpected error occurred. Please try again.');
                        }
                    }
                } finally {
                    setLoading(false)
                }
            }
            getData()
        }
        // Cleanup function to cancel the request when the component unmounts
        return () => {
            source.cancel('Request canceled by cleanup');
        };
    }, [callback, site_id])


    return {
        loading: [loading, setLoading],
        siteStaffsData: [siteStaffsData, setSiteStaffsData],
        error: [error, setError],
        callback: [callback, setCallback],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        total: [total, setTotal]
    }
}