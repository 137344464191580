import React, { useContext } from 'react';
import { GlobalState } from '../../GlobalState';
import { Link } from 'react-router-dom';

function Header() {
    const state = useContext(GlobalState)
    const [userData] = state.userData;
    const [siteData] = state.siteData

    return (
        <div className='w-100 d-flex justify-content-between p-3' style={{ background: '#fff', color: "#333" }}>
            <h6>
                Phoenix TMG - STUDIES PORTAL
            </h6>

            <div className='d-flex'>
                <li className='me-2'>
                    {
                        userData.active_site === false
                            ? <Link to="/new-site"><button className='btn-b'>Add Site</button></Link>
                            : <>
                                <button className='btn-b me-3'>
                                    {siteData.site_name}
                                </button>
                            </>
                    }
                </li>
                <li> {userData.fullname} </li>
            </div>
        </div>
    );
}

export default Header;