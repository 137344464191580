import React, { useContext, useEffect, useState } from "react";
import CSidebar from "../../../components/Nav/CSidebar";
import Header from "../../../components/Header/Header";
import { Parser } from 'html-to-react'
import { TiArrowBack } from "react-icons/ti";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { GlobalState } from "../../../GlobalState";
import { TiArrowMaximise } from "react-icons/ti";
import { FaDownload } from "react-icons/fa";

export default function DiscoverStudyDetails() {
    const state = useContext(GlobalState);
    const [userData] = state.userData;
    const [siteData] = state.siteData;
    // const [studiesData] = state.studiesApi.studiesData;
    const [, setAlert] = state.alert;

    const { id } = useParams();
    const [study, setStudy] = useState(null)

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    useEffect(() => {
        if (id) {
            const getData = async () => {
                // let studyData = await studiesData.find(e => e._id === id)
                // setStudy(studyData)
                try {
                    let res = await axios.get(`${API_ENDPOINT}/studies/${id}`);
                    setStudy(res.data.item);
                } catch (error) {
                    console.error("Error fetching study data:", error);
                    // Handle error (e.g., set an error state or show an alert)
                }
            }

            getData()
        }
    }, [id, API_ENDPOINT])


    const joinWaitlist = async (staffId) => {
        console.log(study)
        try {
            const payload = {
                user: userData._id,
                site: siteData._id,
                study: id,
                staff: staffId
            };

            console.log('Payload:', payload);

            const res = await axios.post(`${API_ENDPOINT}/study-interests`, payload);

            setAlert({
                failure: false,
                success: true,
                failureMsg: res.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, success: false }), 2000);
        } catch (error) {
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const openConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const confirmJoinWaitlist = async () => {
        if (study && study.staff) {
            await joinWaitlist(study.staff._id); // Passing staff ID directly
        } else {
            console.error("No study or staff found.");
        }
        closeConfirmationModal();
    };

    useEffect(() => {
        const modalElement = document.getElementById("staticBackdrop");
        if (modalElement) {
            setConfirmationModal(new window.bootstrap.Modal(modalElement));
        }
    }, []);

    useEffect(() => {
        if (confirmationModal) {
            if (showConfirmationModal) {
                confirmationModal.show();
            } else {
                confirmationModal.hide();
            }
        }
    }, [showConfirmationModal, confirmationModal]);

    const downloadDocument = async (fileUrl) => {
        try {
            // Split the file URL to extract the last 3 sections
            const sections = fileUrl.split('/').slice(-3);
            const downloadEndpoint = `${API_ENDPOINT}/download/${sections.join('/')}`;

            // Create a temporary link element
            const link = document.createElement('a');
            link.href = downloadEndpoint;
            link.setAttribute('target', '_blank'); // Open in a new tab
            document.body.appendChild(link);

            // Simulate click to trigger download
            link.click();

            // Cleanup
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            // Handle error if needed
            setAlert({
                failure: true,
                success: false,
                failureMsg: error.response.data.msg,
                successMsg: '',
            });
            setTimeout(() => setAlert({ ...alert, failure: false }), 2000);
        }
    }


    return (
        <>
            <div className="d-flex profile">
                <div>
                    <CSidebar />
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", flexFlow: "column", height: "100vh", overflowY: "hidden" }}>
                    <Header />

                    <div className="bg-light" style={{ height: "100%" }}>
                        <div style={{ height: "calc(100% - 64px)", padding: "20px 5%", overflowY: "scroll" }}>
                            <div className="mb-4">
                                <Link to="/my-studies">
                                    <button className="btn btn-sm btn-outline-danger">
                                        <span className="me-2"><TiArrowBack /></span>
                                        Back
                                    </button>
                                </Link>
                            </div>

                            <div className="py-3 mb-4">
                                <div className="row bg-white p-3 mb-4">
                                    <div className="col-lg-4">
                                        <p>
                                            <small className="d-block"> REF NO:</small>
                                            <b> {study && study.study_id ? `${study.study_id}` : "unknown"} </b>
                                        </p>
                                        <p>
                                            <small className="d-block"> Submission Date:</small>
                                            <b>
                                                {study && study.deadline_for_submission ? `${study.deadline_for_submission}` : "unknown"}
                                            </b>
                                        </p>
                                        <p>
                                            <small className="d-block"> PhoenixTMG Contact:</small>
                                            <button className="btn btn-sm btn-outline-secondary mt-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                                <i>
                                                    {study && study.staff.firstname ? `${study.staff.firstname} ` : "unknown"}
                                                    {study && study.staff.lastname ? `${study.staff.lastname}` : "unknown"}
                                                </i>
                                                <span className="ms-2 fw-bold">
                                                    <TiArrowMaximise />
                                                </span>
                                            </button>
                                        </p>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                                            <button className="btn btn-outline-secondary me-2" type="button">
                                                Awarded with CRO
                                            </button>
                                            <button type="button" className="btn-a" onClick={openConfirmationModal} >
                                                Join Waitlist
                                            </button>
                                        </div>
                                        <h5 className="mb-3"> {study && study.study_title ? `${study.study_title}` : "unknown"} </h5>

                                        <p className="mb-3" style={{ fontSize: '14px' }}>
                                            {study && study.study_description ? `${study.study_description}` : "unknown"}
                                        </p>
                                    </div>
                                </div>

                                <div className="row bg-white p-3">
                                    <div className="col-lg-12 py-3">
                                        <div>
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active mb-3" id="pills-keyInfo-tab" data-bs-toggle="pill" data-bs-target="#pills-keyInfo" type="button" role="tab" aria-controls="pills-keyInfo" aria-selected="true">
                                                        Key Information
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-inclusionCriteria-tab" data-bs-toggle="pill" data-bs-target="#pills-inclusionCriteria" type="button" role="tab" aria-controls="pills-inclusionCriteria" aria-selected="false">
                                                        Inclusion Criteria
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-exclusionCriteria-tab" data-bs-toggle="pill" data-bs-target="#pills-exclusionCriteria" type="button" role="tab" aria-controls="pills-exclusionCriteria" aria-selected="false">
                                                        Exclusion Criteria
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-studyTimeline-tab" data-bs-toggle="pill" data-bs-target="#pills-studyTimeline" type="button" role="tab" aria-controls="pills-studyTimeline" aria-selected="false">
                                                        Study Timeline
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-equipmentNeeded-tab" data-bs-toggle="pill" data-bs-target="#pills-equipmentNeeded" type="button" role="tab" aria-controls="pills-equipmentNeeded" aria-selected="false" >
                                                        Equipment Needed
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-studyDocs-tab" data-bs-toggle="pill" data-bs-target="#pills-studyDocs" type="button" role="tab" aria-controls="pills-studyDocs" aria-selected="false" >
                                                        Study Documents
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link mb-3" id="pills-additionalInfo-tab" data-bs-toggle="pill" data-bs-target="#pills-additionalInfo" type="button" role="tab" aria-controls="pills-additionalInfo" aria-selected="false" >
                                                        Additional Information
                                                    </button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-keyInfo" role="tabpanel" aria-labelledby="pills-keyInfo-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">key Information</h6>

                                                        <ul className="list-group">
                                                            <li>
                                                                Indication:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.indications ? `${study.indications}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                CRO Name:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.cro_name ? `${study.cro_name}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Sponsor Name:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.sponsor_name ? `${study.sponsor_name}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Protocol Number:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.protocol_number ? `${study.protocol_number}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Total Number of Sites:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.total_number_of_sites ? `${study.total_number_of_sites}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Preferred PI Specialty/Specialties:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.preffered_investigator_specialty ? `${study.preffered_investigator_specialty}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Experience:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.experience ? `${study.experience}` : "unknown"}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-inclusionCriteria" role="tabpanel" aria-labelledby="pills-inclusionCriteria-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Inclusion Criteria</h6>

                                                        {
                                                            study && study.inclusionCriteria ?
                                                                <>
                                                                    <div>
                                                                        {Parser().parse(study.inclusionCriteria)}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <p>Information will be provided when available.</p>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-exclusionCriteria" role="tabpanel" aria-labelledby="pills-exclusionCriteria-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Exclusion Criteria</h6>

                                                        {
                                                            study && study.exclusionCriteria ?
                                                                <>
                                                                    <div>
                                                                        {Parser().parse(study.exclusionCriteria)}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <p>Information will be provided when available.</p>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-studyTimeline" role="tabpanel" aria-labelledby="pills-studyTimeline-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Study Timeline</h6>

                                                        <ul className="list-group">
                                                            <li>
                                                                Start Date:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.start_date ? `${study.start_date}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Enrollment Period:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.enrollment_period ? `${study.enrollment_period}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Study Duration:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.study_duration ? `${study.study_duration}` : "unknown"}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                Deadline for Submission:
                                                                <span className="fw-bold ms-2">
                                                                    {study && study.deadline_for_submission ? `${study.deadline_for_submission}` : "unknown"}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-equipmentNeeded" role="tabpanel" aria-labelledby="pills-equipmentNeeded-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Equipment Needed</h6>

                                                        {
                                                            study && study.equipmentNeeded ?
                                                                <>
                                                                    <div>
                                                                        {Parser().parse(study.equipmentNeeded)}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <p>Information will be provided when available.</p>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-studyDocs" role="tabpanel" aria-labelledby="pills-studyDocs-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Study Documents</h6>

                                                        {study && study.study_documents && study.study_documents.length === 0 ? (
                                                            <div>
                                                                <p>Study documents will be provided when available.</p>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {study && study.study_documents && (
                                                                    <ul>
                                                                        {study.study_documents.map((e, i) => (
                                                                            <li key={i} className="d-flex justify-content-between">
                                                                                {e.name}

                                                                                <span>
                                                                                    <button className="btn btn-sm btn-dark"
                                                                                        onClick={() => downloadDocument(e.url)}
                                                                                    >
                                                                                        <FaDownload />
                                                                                    </button>
                                                                                </span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-additionalInfo" role="tabpanel" aria-labelledby="pills-additionalInfo-tab" tabIndex="0">
                                                    <div className="card p-3">
                                                        <h6 className="d-inline-block">Additional Information</h6>

                                                        {
                                                            study && study.additionalInfo ?
                                                                <>
                                                                    <div>
                                                                        {Parser().parse(study.additionalInfo)}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <p>Information will be provided when available.</p>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* PHOENIXTMG CONTACT */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <div className="">
                        <small>PhoenixTMG Contact</small>
                        <h5 className="offcanvas-title" id="offcanvasRightLabel">
                            {study && study.staff.firstname ? `${study.staff.firstname} ` : "unknown"}
                            {study && study.staff.lastname ? `${study.staff.lastname} ` : "unknown"}
                        </h5>
                    </div>
                    <button className="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <small className="pb-3 fs-6">Contact Information</small>
                    <ul className="list-group mt-3">
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Email</div>
                                {study && study.staff.email ? `${study.staff.email}` : "unknown"}
                            </div>
                            {/* <span class="badge bg-primary rounded-pill">14</span> */}
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Phone number</div>
                                {study && study.staff.phonenumber ? `${study.staff.phonenumber}` : "unknown"}
                            </div>
                            {/* <span class="badge bg-primary rounded-pill">14</span> */}
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Position</div>
                                {study && study.staff.phonenumber ? `${study.staff.phonenumber}` : "unknown"}
                            </div>
                            {/* <span class="badge bg-primary rounded-pill">14</span> */}
                        </li>
                    </ul>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade"
                id="staticBackdrop"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!showConfirmationModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Confirm your action</h1>
                            <button className="button" class="btn-close" onClick={closeConfirmationModal}></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to join our waitlist?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn-d-sm"
                                onClick={closeConfirmationModal}
                            >No</button>
                            <button type="button" className="btn-a-sm"
                                onClick={confirmJoinWaitlist}
                            >Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}